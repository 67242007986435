import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import '../App.css';

const MotionListItem = motion(ListItem);

const menuItems = [
    { text: 'Home', icon: <HomeIcon />, path: '/' },
    { text: 'Residents', icon: <PeopleIcon />, path: '/residents' },
    { text: 'HOA Invoices', icon: <ReceiptIcon />, path: '/invoices' },
    { text: 'HOA Dues', icon: <PaymentIcon />, path: '/payments' },
    { text: 'Bills Payable', icon: <PaymentIcon />, path: '/bills-payable' },
    { text: 'Ledger', icon: <AccountBalanceIcon />, path: '/ledger' },
];

const listVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.2,
            when: "beforeChildren",
            staggerChildren: 0.05
        }
    }
};

const itemVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: { duration: 0.1 }
    }
};

const Sidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { logout } = useAuth();

    const handleNavigation = (path) => {
        navigate(path);
    };

    const handleLogout = async () => {
        await logout();
        navigate('/login');
    };

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: 280,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 280,
                    boxSizing: 'border-box',
                    backgroundColor: '#18181B',
                    borderRight: '1px solid #27272A',
                }
            }}
        >
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '24px 20px',
                        borderBottom: '1px solid #27272A',
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            color: '#FFFFFF',
                            fontWeight: 600,
                            letterSpacing: '-0.02em',
                        }}
                    >
                        myHOA Portal
                    </Typography>
                </Box>

                <List
                    component={motion.div}
                    variants={listVariants}
                    initial="hidden"
                    animate="visible"
                    sx={{
                        padding: '16px',
                        flex: 1,
                    }}
                >
                    {menuItems.map((item) => (
                        <MotionListItem
                            key={item.text}
                            variants={itemVariants}
                            onClick={() => handleNavigation(item.path)}
                            sx={{
                                borderRadius: '12px',
                                mb: 0.5,
                                backgroundColor: location.pathname === item.path ? '#27272A' : 'transparent',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: '#27272A',
                                },
                            }}
                        >
                            <ListItemIcon sx={{ 
                                color: location.pathname === item.path ? '#FFFFFF' : '#71717A',
                                minWidth: 40,
                            }}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText 
                                primary={item.text}
                                sx={{
                                    '& .MuiListItemText-primary': {
                                        color: location.pathname === item.path ? '#FFFFFF' : '#A1A1AA',
                                        fontWeight: location.pathname === item.path ? 600 : 400,
                                    }
                                }}
                            />
                        </MotionListItem>
                    ))}

                    <MotionListItem
                        variants={itemVariants}
                        onClick={handleLogout}
                        sx={{
                            borderRadius: '12px',
                            mt: 2,
                            cursor: 'pointer',
                            backgroundColor: 'transparent',
                            '&:hover': {
                                backgroundColor: '#27272A',
                            },
                        }}
                    >
                        <ListItemIcon sx={{ 
                            color: '#71717A',
                            minWidth: 40,
                        }}>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText 
                            primary="Logout"
                            sx={{
                                '& .MuiListItemText-primary': {
                                    color: '#A1A1AA',
                                }
                            }}
                        />
                    </MotionListItem>
                </List>

                <Box
                    sx={{
                        padding: '16px',
                        borderTop: '1px solid #27272A',
                        textAlign: 'center',
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            color: '#71717A',
                            fontSize: '0.75rem',
                        }}
                    >
                        Version 1.0P
                    </Typography>
                </Box>
            </Box>
        </Drawer>
    );
};

export default Sidebar;
