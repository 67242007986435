import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { TextField, Button, Typography, Container, Box, CssBaseline, Avatar, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, Link } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { db } from '../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import axios from 'axios';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#FFFFFF',
        },
        secondary: {
            main: '#A1A1AA',
        },
        background: {
            default: '#18181B',
            paper: '#27272A',
        },
        text: {
            primary: '#FFFFFF',
            secondary: '#A1A1AA',
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: '#27272A',
                        borderRadius: '12px',
                        transition: 'all 0.15s cubic-bezier(0.4, 0, 0.2, 1)',
                        '&:hover': {
                            backgroundColor: '#3F3F46',
                            transform: 'translateY(-1px)',
                        },
                        '& fieldset': {
                            borderColor: '#3F3F46',
                            borderRadius: '12px',
                            transition: 'border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1)',
                        },
                        '&:hover fieldset': {
                            borderColor: '#52525B',
                        },
                        '&.Mui-focused': {
                            transform: 'translateY(-1px)',
                            '& fieldset': {
                                borderColor: '#71717A',
                                borderWidth: '1px',
                            },
                        },
                        '& input': {
                            '&:-webkit-autofill': {
                                '-webkit-box-shadow': '0 0 0 100px #27272A inset',
                                '-webkit-text-fill-color': '#FFFFFF',
                                'caret-color': '#FFFFFF',
                                'borderRadius': '12px',
                            },
                        },
                    },
                    '& .MuiInputLabel-root': {
                        color: '#A1A1AA',
                        transition: 'color 0.15s cubic-bezier(0.4, 0, 0.2, 1)',
                        '&.Mui-focused': {
                            color: '#E4E4E7',
                        },
                    },
                    '& .MuiInputBase-input': {
                        '&::selection': {
                            backgroundColor: '#3F3F46',
                        },
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '12px',
                    textTransform: 'none',
                    padding: '12px',
                    fontWeight: 500,
                    backgroundColor: '#FFFFFF',
                    color: '#18181B',
                    transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                    '&:hover': {
                        backgroundColor: '#F4F4F5',
                        transform: 'translateY(-2px)',
                    },
                    '&:active': {
                        transform: 'translateY(0)',
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#71717A',
                    borderRadius: '6px',
                    transition: 'all 0.15s cubic-bezier(0.4, 0, 0.2, 1)',
                    '&.Mui-checked': {
                        color: '#FFFFFF',
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        transform: 'scale(1.05)',
                    },
                },
            },
        },
    },
    typography: {
        fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
        h4: {
            fontWeight: 600,
            letterSpacing: '-0.02em',
        },
        body2: {
            fontSize: '0.9375rem',
        },
    },
});

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [consent, setConsent] = useState(false);
    const [open, setOpen] = useState(false);
    const [mobileWarning, setMobileWarning] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setMobileWarning(true);
        }
    }, []);

    const fetchIPAndLocation = async () => {
        try {
            const response = await axios.get('https://ipapi.co/json/');
            return {
                ip: response.data.ip,
                location: `${response.data.city}, ${response.data.region}, ${response.data.country_name}`
            };
        } catch (error) {
            console.error("Failed to fetch IP and location:", error);
            return { ip: 'N/A', location: 'N/A' };
        }
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        if (!consent) {
            alert("You must agree to the terms and conditions.");
            return;
        }
        const auth = getAuth();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            
            const { ip, location } = await fetchIPAndLocation();

            const session = {
                uid: user.uid,
                email: user.email,
                time: new Date().toISOString(),
                ip: ip,
                location: location
            };
            await addDoc(collection(db, 'loginSessions'), session);

            if (mobileWarning) {
                alert("Warning: This site has some compatibility issues with mobile devices and is currently under development! Full support is available from a desktop device.");
            }

            navigate('/');
        } catch (error) {
            alert("Failed to log in: " + error.message);
        }
    };

    const handleClickOpen = (event) => {
        event.preventDefault();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '100vh',
                    background: '#18181B',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Container component="main" maxWidth="sm">
                    <CssBaseline />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            backgroundColor: '#27272A',
                            padding: { xs: 3, sm: 4 },
                            borderRadius: '16px',
                            border: '1px solid #3F3F46',
                            animation: 'fadeIn 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                            position: 'relative',
                            '@keyframes fadeIn': {
                                '0%': {
                                    opacity: 0,
                                    transform: 'translateY(20px) scale(0.98)',
                                },
                                '100%': {
                                    opacity: 1,
                                    transform: 'translateY(0) scale(1)',
                                },
                            },
                            '&::after': {
                                content: '""',
                                position: 'absolute',
                                inset: '-1px',
                                borderRadius: '16px',
                                padding: '1px',
                                background: 'linear-gradient(to bottom right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05))',
                                WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                                WebkitMaskComposite: 'xor',
                                maskComposite: 'exclude',
                                pointerEvents: 'none',
                                opacity: 0.5,
                            },
                        }}
                    >
                        <Avatar
                            sx={{
                                m: 1,
                                mb: 2,
                                bgcolor: '#3F3F46',
                                width: 48,
                                height: 48,
                                border: '1px solid #52525B',
                                borderRadius: '14px',
                                transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                                '&:hover': {
                                    transform: 'scale(1.05) rotate(5deg)',
                                    bgcolor: '#52525B',
                                },
                            }}
                        >
                            <LockOutlinedIcon sx={{ fontSize: 24, color: '#FFFFFF' }} />
                        </Avatar>
                        <Typography
                            component="h1"
                            variant="h4"
                            sx={{
                                mb: 4,
                                color: '#FFFFFF',
                                fontWeight: 600,
                                textAlign: 'center',
                                transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                                '&:hover': {
                                    letterSpacing: '-0.03em',
                                },
                            }}
                        >
                            Welcome to myHOA
                        </Typography>
                        <Box 
                            component="form" 
                            onSubmit={handleLogin} 
                            noValidate 
                            sx={{ 
                                mt: 1, 
                                width: '100%',
                                '& .MuiTextField-root + .MuiTextField-root': {
                                    mt: 2,
                                },
                            }}
                        >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Username"
                                name="email"
                                autoComplete="off"
                                autoFocus
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="off"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                sx={{ mb: 3 }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={consent}
                                        onChange={() => setConsent(!consent)}
                                        sx={{
                                            borderRadius: '6px',
                                        }}
                                    />
                                }
                                label={
                                    <Typography variant="body2" sx={{ color: '#A1A1AA' }}>
                                        I agree to these{' '}
                                        <Link
                                            component="button"
                                            onClick={handleClickOpen}
                                            sx={{
                                                color: '#FFFFFF',
                                                textDecoration: 'none',
                                                position: 'relative',
                                                '&:hover': {
                                                    textDecoration: 'none',
                                                    '&::after': {
                                                        width: '100%',
                                                    },
                                                },
                                                '&::after': {
                                                    content: '""',
                                                    position: 'absolute',
                                                    width: '0%',
                                                    height: '1px',
                                                    bottom: '-1px',
                                                    left: '0',
                                                    backgroundColor: '#FFFFFF',
                                                    transition: 'width 0.2s ease',
                                                },
                                            }}
                                        >
                                            terms
                                        </Link>
                                    </Typography>
                                }
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    height: '48px',
                                    fontSize: '0.9375rem',
                                    fontWeight: 600,
                                    boxShadow: 'none',
                                    '&:hover': {
                                        boxShadow: 'none',
                                    },
                                }}
                            >
                                Sign In
                            </Button>
                        </Box>
                    </Box>
                </Container>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="md"
                    fullWidth
                    TransitionProps={{
                        timeout: 300,
                    }}
                    PaperProps={{
                        sx: {
                            backgroundColor: '#27272A',
                            borderRadius: '16px',
                            border: '1px solid #3F3F46',
                            overflow: 'hidden',
                            transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                        },
                    }}
                >
                    <DialogTitle 
                        sx={{ 
                            color: '#FFFFFF',
                            borderBottom: '1px solid #3F3F46',
                            padding: '20px 24px',
                        }}
                    >
                        Terms and Conditions
                    </DialogTitle>
                    <DialogContent sx={{ padding: '24px' }}>
                        <iframe 
                            src="/terms.pdf" 
                            width="100%" 
                            height="600px" 
                            style={{ 
                                border: 'none',
                                borderRadius: '12px',
                                backgroundColor: '#18181B',
                            }} 
                            title="Terms and Conditions" 
                        />
                    </DialogContent>
                    <DialogActions 
                        sx={{ 
                            padding: '16px 24px',
                            borderTop: '1px solid #3F3F46',
                        }}
                    >
                        <Button
                            onClick={handleClose}
                            sx={{
                                color: '#FFFFFF',
                                backgroundColor: '#3F3F46',
                                borderRadius: '10px',
                                padding: '8px 16px',
                                '&:hover': {
                                    backgroundColor: '#52525B',
                                },
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </ThemeProvider>
    );
};

export default Login;
