import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, 
  Container, Typography, IconButton, InputAdornment, Box, Stack, Card, 
  Divider, Tooltip, alpha, useTheme, Alert, Snackbar,
  Skeleton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const LoadingSkeleton = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ mb: 3 }}>
        <Skeleton variant="rectangular" height={48} sx={{ borderRadius: 2, mb: 2 }} />
        <Skeleton variant="rectangular" height={56} sx={{ borderRadius: 2 }} />
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><Skeleton variant="text" width={100} /></TableCell>
              <TableCell><Skeleton variant="text" width={120} /></TableCell>
              <TableCell><Skeleton variant="text" width={100} /></TableCell>
              <TableCell><Skeleton variant="text" width={100} /></TableCell>
              <TableCell><Skeleton variant="text" width={100} /></TableCell>
              <TableCell><Skeleton variant="text" width={100} /></TableCell>
              <TableCell><Skeleton variant="text" width={80} /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(5)].map((_, index) => (
              <TableRow key={index}>
                <TableCell><Skeleton variant="text" width={80} /></TableCell>
                <TableCell><Skeleton variant="text" width={120} /></TableCell>
                <TableCell><Skeleton variant="text" width={80} /></TableCell>
                <TableCell><Skeleton variant="text" width={80} /></TableCell>
                <TableCell><Skeleton variant="text" width={80} /></TableCell>
                <TableCell><Skeleton variant="text" width={80} /></TableCell>
                <TableCell><Skeleton variant="text" width={60} /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const BillsPayable = () => {
  const theme = useTheme();
  const [bills, setBills] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [checkNumberDialogOpen, setCheckNumberDialogOpen] = useState(false);
  const [loading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [newBill, setNewBill] = useState({
    billDate: '',
    dateDue: '',
    payableTo: '',
    amount: '',
    invoiceNumber: '',
    paid: false,
    datePaid: '',
  });
  const [currentBill, setCurrentBill] = useState(null);
  const [checkNumber, setCheckNumber] = useState('');
  const [confirmUnpaidDialogOpen, setConfirmUnpaidDialogOpen] = useState(false);
  const [billToUnpaid, setBillToUnpaid] = useState(null);
  const [sortField, setSortField] = useState('billDate');
  const [sortDirection, setSortDirection] = useState('desc');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchBills = async () => {
      const billsCollection = collection(db, 'bills');
      const billsSnapshot = await getDocs(billsCollection);
      const billsList = billsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          billDate: data.billDate ? data.billDate.toDate() : null,
          dateDue: data.dateDue ? data.dateDue.toDate() : null,
          datePaid: data.datePaid ? data.datePaid.toDate() : null,
        };
      });
      // Sort bills by most recent first
      const sortedBills = billsList.sort((a, b) => {
        const dateA = a.billDate || new Date(0);
        const dateB = b.billDate || new Date(0);
        return dateB - dateA;
      });
      setBills(sortedBills);
    };

    fetchBills();
  }, []);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setNewBill({
      billDate: '',
      dateDue: '',
      payableTo: '',
      amount: '',
      invoiceNumber: '',
      paid: false,
      datePaid: '',
    });
  };

  const handleEditDialogOpen = (bill) => {
    setCurrentBill(bill);
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setCurrentBill(null);
  };

  const handleCheckNumberDialogOpen = (bill) => {
    setCurrentBill(bill);
    setCheckNumberDialogOpen(true);
  };

  const handleCheckNumberDialogClose = () => {
    setCheckNumberDialogOpen(false);
    setCheckNumber('');
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('desc');
    }
  };

  const sortedBills = [...bills].sort((a, b) => {
    const dateA = a[sortField] || new Date(0);
    const dateB = b[sortField] || new Date(0);
    return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
  });

  const filteredAndSortedBills = sortedBills.filter(bill => {
    if (!searchQuery) return true;
    
    const searchLower = searchQuery.toLowerCase();
    return (
      (bill.payableTo && bill.payableTo.toLowerCase().includes(searchLower)) ||
      (bill.invoiceNumber && bill.invoiceNumber.toLowerCase().includes(searchLower)) ||
      (bill.amount && bill.amount.toString().includes(searchLower)) ||
      (bill.billDate && bill.billDate.toLocaleDateString().includes(searchLower)) ||
      (bill.dateDue && bill.dateDue.toLocaleDateString().includes(searchLower)) ||
      (bill.datePaid && bill.datePaid.toLocaleDateString().includes(searchLower))
    );
  });

  const handleAddBill = async () => {
    try {
      const billsCollection = collection(db, 'bills');
      const billData = {
        ...newBill,
        billDate: new Date(newBill.billDate + 'T00:00:00'),
        dateDue: new Date(newBill.dateDue + 'T00:00:00'),
        datePaid: newBill.datePaid ? new Date(newBill.datePaid + 'T00:00:00') : null
      };
      const billDoc = await addDoc(billsCollection, billData);
      setBills([...bills, { ...billData, id: billDoc.id }]);
      handleDialogClose();
    } catch (error) {
      console.error("Error adding bill: ", error);
    }
  };

  const handleEditBill = async () => {
    try {
      const billDoc = doc(db, 'bills', currentBill.id);
      const updatedBill = {
        ...currentBill,
        billDate: new Date(currentBill.billDate + 'T00:00:00'),
        dateDue: new Date(currentBill.dateDue + 'T00:00:00'),
        datePaid: currentBill.datePaid ? new Date(currentBill.datePaid + 'T00:00:00') : null
      };
      await updateDoc(billDoc, updatedBill);
      setBills(bills.map(bill => bill.id === currentBill.id ? updatedBill : bill));
      handleEditDialogClose();
    } catch (error) {
      console.error("Error updating bill: ", error);
    }
  };

  const handleDeleteBill = async (id) => {
    try {
      const billDoc = doc(db, 'bills', id);
      await deleteDoc(billDoc);
      setBills(bills.filter(bill => bill.id !== id));
    } catch (error) {
      console.error("Error deleting bill: ", error);
    }
  };

  const handleTogglePaid = (bill) => {
    if (!bill.paid) {
      handleCheckNumberDialogOpen(bill);
    } else {
      setBillToUnpaid(bill);
      setConfirmUnpaidDialogOpen(true);
    }
  };

  const updateBillPaidStatus = async (bill, paid, checkNumber) => {
    try {
      const billDoc = doc(db, 'bills', bill.id);
      const updatedBill = {
        ...bill,
        paid: paid,
        datePaid: paid ? serverTimestamp() : null,
        checkNumber: checkNumber
      };

      await updateDoc(billDoc, updatedBill);

      const newBills = bills.map(b => {
        if (b.id === bill.id) {
          return {
            ...b,
            paid: paid,
            datePaid: paid ? new Date() : null,
            checkNumber: checkNumber
          };
        }
        return b;
      });

      setBills(newBills);
      handleCheckNumberDialogClose();
    } catch (error) {
      console.error("Error updating bill: ", error);
    }
  };

  const handleSaveCheckNumber = () => {
    updateBillPaidStatus(currentBill, true, checkNumber);
  };

  const handleExportBills = () => {
    const sortedBills = [...bills].sort((a, b) => new Date(b.billDate) - new Date(a.billDate));

    const doc = new jsPDF({ orientation: 'landscape' });
    doc.text('Bills Report', 14, 16);
    doc.autoTable({
      head: [['Bill Date', 'Date Due', 'Payable To', 'Amount', 'Invoice #', 'Paid', 'Date Paid', 'Check #']],
      body: sortedBills.map(bill => [
        bill.billDate ? bill.billDate.toLocaleDateString() : '',
        bill.dateDue ? bill.dateDue.toLocaleDateString() : '',
        bill.payableTo,
        `$${parseFloat(bill.amount).toLocaleString()}`,
        bill.invoiceNumber,
        bill.paid ? 'Yes' : 'No',
        bill.datePaid ? bill.datePaid.toLocaleDateString() : '',
        bill.checkNumber
      ]),
      theme: 'striped',
      startY: 20,
      headStyles: { fillColor: [26, 29, 36] }
    });
    doc.save('bills_report.pdf');
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleConfirmUnpaid = () => {
    if (billToUnpaid) {
      updateBillPaidStatus(billToUnpaid, false, '');
      showSnackbar('Bill marked as unpaid');
    }
    setConfirmUnpaidDialogOpen(false);
    setBillToUnpaid(null);
  };

  const handleCancelUnpaid = () => {
    setConfirmUnpaidDialogOpen(false);
    setBillToUnpaid(null);
  };

  if (loading) {
    return (
      <Container maxWidth="xl">
        <Box sx={{ py: 4 }}>
          <LoadingSkeleton />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 4 }}>
        <Box 
          sx={{ 
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'flex-start', md: 'center' },
            justifyContent: 'space-between',
            mb: 4,
            gap: 2
          }}
        >
          <Box>
            <Typography 
              variant="h4" 
              component="h1" 
              sx={{ 
                fontWeight: 700,
                color: theme => theme.palette.text.primary,
                mb: 1
              }}
            >
              Bills Management
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ 
                color: theme => theme.palette.text.secondary,
                maxWidth: 600
              }}
            >
              Track and manage bills payable, record payments, and generate financial reports.
            </Typography>
          </Box>
          
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} width={{ xs: '100%', sm: 'auto' }}>
            <Button
              variant="outlined"
              startIcon={<DownloadIcon />}
              onClick={handleExportBills}
              sx={{
                borderRadius: 2,
                textTransform: 'none',
                px: 3,
                py: 1.5,
                borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                color: 'text.primary',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                }
              }}
            >
              Export Bills
            </Button>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleDialogOpen}
              sx={{
                borderRadius: 2,
                textTransform: 'none',
                px: 3,
                py: 1.5,
                bgcolor: '#2d3748',
                color: '#fff',
                boxShadow: 2,
                '&:hover': {
                  bgcolor: '#3a4a61',
                }
              }}
            >
              Add Bill
            </Button>
          </Stack>
        </Box>

        <Alert 
          severity="info" 
          sx={{ 
            mb: 3,
            borderRadius: 2,
            '& .MuiAlert-icon': {
              color: 'primary.main'
            }
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 500, mb: 0.5 }}>
            Sorting Tips:
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            • Click on "Due Date" or "Paid Date" column headers to sort by that field
            <br />
            • Click again to toggle between ascending (↑) and descending (↓) order
            <br />
            • Bills are sorted by most recent first by default
          </Typography>
        </Alert>

        <Card elevation={2}>
          <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search bills..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'text.secondary' }} />
                  </InputAdornment>
                ),
                endAdornment: searchQuery && (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => setSearchQuery('')}
                      sx={{ color: 'text.secondary' }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                }
              }}
            />
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell 
                    sx={{ 
                      fontWeight: 600,
                      cursor: 'pointer',
                      '&:hover': { bgcolor: theme => alpha(theme.palette.primary.main, 0.04) }
                    }}
                    onClick={() => handleSort('billDate')}
                  >
                    Due Date {sortField === 'billDate' && (sortDirection === 'asc' ? '↑' : '↓')}
                  </TableCell>
                  <TableCell 
                    sx={{ 
                      fontWeight: 600,
                      cursor: 'pointer',
                      '&:hover': { bgcolor: theme => alpha(theme.palette.primary.main, 0.04) }
                    }}
                    onClick={() => handleSort('dateDue')}
                  >
                    Paid Date {sortField === 'dateDue' && (sortDirection === 'asc' ? '↑' : '↓')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Payable To</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Amount</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Invoice #</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAndSortedBills.map((bill) => (
                  <TableRow 
                    key={bill.id}
                    sx={{ 
                      '&:hover': { 
                        bgcolor: theme => alpha(theme.palette.primary.main, 0.04)
                      }
                    }}
                  >
                    <TableCell>{bill.billDate ? bill.billDate.toLocaleDateString() : 'Pending'}</TableCell>
                    <TableCell>{bill.dateDue ? bill.dateDue.toLocaleDateString() : 'Pending'}</TableCell>
                    <TableCell>{bill.payableTo || 'Pending'}</TableCell>
                    <TableCell>
                      {bill.amount ? 
                        `$${parseFloat(bill.amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}` : 
                        'Pending'
                      }
                    </TableCell>
                    <TableCell>{bill.invoiceNumber || 'Pending'}</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          px: 1,
                          py: 0.5,
                          borderRadius: 1,
                          bgcolor: bill.paid ? 'success.soft' : 'warning.soft',
                          color: bill.paid ? 'success.main' : 'warning.main',
                        }}
                      >
                        <Typography variant="body2">
                          {bill.paid ? 'Paid' : 'Pending'}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title={bill.paid ? "Mark as Unpaid" : "Mark as Paid"}>
                          <IconButton
                            size="small"
                            onClick={() => handleTogglePaid(bill)}
                            sx={{ 
                              color: bill.paid ? 'success.main' : 'warning.main',
                              '&:hover': {
                                bgcolor: bill.paid 
                                  ? alpha(theme.palette.success.main, 0.04)
                                  : alpha(theme.palette.warning.main, 0.04),
                              }
                            }}
                          >
                            {bill.paid ? <CheckCircleIcon fontSize="small" /> : <CancelIcon fontSize="small" />}
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                          <IconButton
                            size="small"
                            onClick={() => handleEditDialogOpen(bill)}
                            sx={{ 
                              color: 'primary.main',
                              '&:hover': {
                                bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                              }
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            size="small"
                            onClick={() => handleDeleteBill(bill.id)}
                            sx={{ 
                              color: 'error.main',
                              '&:hover': {
                                bgcolor: theme => alpha(theme.palette.error.main, 0.04),
                              }
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>

        <Dialog 
          open={openDialog} 
          onClose={handleDialogClose}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 2
            }
          }}
        >
          <DialogTitle sx={{ pb: 1 }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
              Add New Bill
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ pt: 2 }}>
            <Stack spacing={3}>
              <TextField 
                label="Bill Date" 
                type="date" 
                fullWidth 
                InputLabelProps={{ shrink: true }} 
                value={newBill.billDate} 
                onChange={(e) => setNewBill({ ...newBill, billDate: e.target.value })}
              />
              <TextField 
                label="Date Due" 
                type="date" 
                fullWidth 
                InputLabelProps={{ shrink: true }} 
                value={newBill.dateDue} 
                onChange={(e) => setNewBill({ ...newBill, dateDue: e.target.value })}
              />
              <TextField 
                label="Payable To" 
                fullWidth 
                value={newBill.payableTo} 
                onChange={(e) => setNewBill({ ...newBill, payableTo: e.target.value })}
              />
              <TextField
                label="Amount"
                fullWidth
                value={newBill.amount}
                onChange={(e) => setNewBill({ ...newBill, amount: e.target.value })}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
              <TextField
                label="Invoice Number"
                fullWidth
                value={newBill.invoiceNumber}
                onChange={(e) => setNewBill({ ...newBill, invoiceNumber: e.target.value })}
              />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 2 }}>
            <Button 
              onClick={handleDialogClose}
              variant="outlined"
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                color: 'text.primary',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                }
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleAddBill}
              variant="contained"
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                boxShadow: 2
              }}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog 
          open={editDialogOpen} 
          onClose={handleEditDialogClose}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 2
            }
          }}
        >
          <DialogTitle sx={{ pb: 1 }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
              Edit Bill
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ pt: 2 }}>
            <Stack spacing={3}>
              <TextField 
                label="Bill Date" 
                type="date" 
                fullWidth 
                InputLabelProps={{ shrink: true }} 
                value={currentBill?.billDate ? currentBill.billDate.toISOString().split('T')[0] : ''} 
                onChange={(e) => setCurrentBill({ ...currentBill, billDate: e.target.value })}
              />
              <TextField 
                label="Date Due" 
                type="date" 
                fullWidth 
                InputLabelProps={{ shrink: true }} 
                value={currentBill?.dateDue ? currentBill.dateDue.toISOString().split('T')[0] : ''} 
                onChange={(e) => setCurrentBill({ ...currentBill, dateDue: e.target.value })}
              />
              <TextField 
                label="Payable To" 
                fullWidth 
                value={currentBill?.payableTo || ''} 
                onChange={(e) => setCurrentBill({ ...currentBill, payableTo: e.target.value })}
              />
              <TextField
                label="Amount"
                fullWidth
                value={currentBill?.amount || ''}
                onChange={(e) => setCurrentBill({ ...currentBill, amount: e.target.value })}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
              <TextField
                label="Invoice Number"
                fullWidth
                value={currentBill?.invoiceNumber || ''}
                onChange={(e) => setCurrentBill({ ...currentBill, invoiceNumber: e.target.value })}
              />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 2 }}>
            <Button 
              onClick={handleEditDialogClose}
              variant="outlined"
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                color: 'text.primary',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                }
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleEditBill}
              variant="contained"
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                boxShadow: 2
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog 
          open={checkNumberDialogOpen} 
          onClose={handleCheckNumberDialogClose}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 2
            }
          }}
        >
          <DialogTitle sx={{ pb: 1 }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
              Enter Check Number
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ pt: 2 }}>
            <Stack spacing={3}>
              {currentBill && (
                <Typography variant="body1" color="text.secondary">
                  Recording payment for bill to: {currentBill.payableTo}
                </Typography>
              )}
              <TextField
                label="Check Number"
                fullWidth
                value={checkNumber}
                onChange={(e) => setCheckNumber(e.target.value)}
                autoFocus
              />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 2 }}>
            <Button 
              onClick={handleCheckNumberDialogClose}
              variant="outlined"
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                color: 'text.primary',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                }
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveCheckNumber}
              variant="contained"
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                boxShadow: 2
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog 
          open={confirmUnpaidDialogOpen} 
          onClose={handleCancelUnpaid}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 2
            }
          }}
        >
          <DialogTitle sx={{ pb: 1 }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
              Confirm Mark as Unpaid
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ pt: 2 }}>
            <Typography variant="body1" color="text.secondary">
              Are you sure you want to mark this bill as unpaid? This will clear the payment date and check number.
              {billToUnpaid && (
                <Box sx={{ mt: 2 }}>
                  <Typography component="div" variant="body2" color="text.primary">
                    <strong>Payable To:</strong> {billToUnpaid.payableTo}
                  </Typography>
                  <Typography component="div" variant="body2" color="text.primary">
                    <strong>Amount:</strong> ${parseFloat(billToUnpaid.amount).toLocaleString()}
                  </Typography>
                  <Typography component="div" variant="body2" color="text.primary">
                    <strong>Check Number:</strong> {billToUnpaid.checkNumber || '—'}
                  </Typography>
                </Box>
              )}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 2 }}>
            <Button 
              onClick={handleCancelUnpaid}
              variant="outlined"
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                color: 'text.primary',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                }
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmUnpaid}
              variant="contained"
              color="error"
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                boxShadow: 2
              }}
            >
              Mark as Unpaid
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar 
          open={openSnackbar} 
          autoHideDuration={6000} 
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={handleSnackbarClose} 
            severity="info" 
            sx={{ 
              width: '100%',
              borderRadius: 2
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default BillsPayable;
