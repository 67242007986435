import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Container, Typography, Box, Select, MenuItem, FormControl, InputLabel, 
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, 
  InputAdornment, Checkbox, Stack, Card, CardContent, Divider, Tooltip, 
  alpha, Alert, Snackbar, IconButton, CircularProgress, Skeleton
} from '@mui/material';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import SortIcon from '@mui/icons-material/Sort';
import FilterListIcon from '@mui/icons-material/FilterList';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const CreditCell = styled(TableCell)(({ theme, entry }) => ({
  color: (entry.type === 'payment' || (entry.type === 'manual' && entry.subType === 'credit')) ? theme.palette.success.main : 'inherit',
}));

const Ledger = () => {
  const [bills, setBills] = useState([]);
  const [payments, setPayments] = useState([]);
  const [manualAdjustments, setManualAdjustments] = useState([]);
  const [residents, setResidents] = useState([]);
  const [balance, setBalance] = useState(0);
  const [sortOption, setSortOption] = useState('date');
  const [filterOption, setFilterOption] = useState('all');
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [openManualDialog, setOpenManualDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [exportOption, setExportOption] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [manualAmount, setManualAmount] = useState('');
  const [manualDescription, setManualDescription] = useState('');
  const [manualType, setManualType] = useState('');
  const [currentEntry, setCurrentEntry] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 30;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showBalance, setShowBalance] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const billsCollection = collection(db, 'bills');
        const billsSnapshot = await getDocs(billsCollection);
        const billsList = billsSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(bill => bill.paid);

        const paymentsCollection = collection(db, 'payments');
        const paymentsSnapshot = await getDocs(paymentsCollection);
        const paymentsList = paymentsSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(payment => payment.isPaid);

        const manualAdjustmentsCollection = collection(db, 'manualLedgerAdjustments');
        const manualAdjustmentsSnapshot = await getDocs(manualAdjustmentsCollection);
        const manualAdjustmentsList = manualAdjustmentsSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            date: data.date instanceof Date ? data.date : data.date.toDate()
          };
        });

        const residentsCollection = collection(db, 'residents');
        const residentsSnapshot = await getDocs(residentsCollection);
        const residentsList = residentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        setBills(billsList);
        setPayments(paymentsList);
        setManualAdjustments(manualAdjustmentsList);
        setResidents(residentsList);

        calculateBalance(billsList, paymentsList, manualAdjustmentsList);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const calculateBalance = (billsList, paymentsList, manualAdjustmentsList) => {
    const totalPayments = paymentsList.reduce((acc, payment) => acc + parseFloat(payment.paymentAmount || 0), 0);
    const totalBills = billsList.reduce((acc, bill) => acc + parseFloat(bill.amount || 0), 0);
    const totalAdjustments = manualAdjustmentsList.reduce((acc, adjustment) => {
      if (adjustment.type === 'manual') {
        return adjustment.subType === 'credit' ? acc + parseFloat(adjustment.amount || 0) : acc - parseFloat(adjustment.amount || 0);
      }
      return acc;
    }, 0);
  
    setBalance(totalPayments - totalBills + totalAdjustments);
  };
  
  const getResidentName = (residentId) => {
    const resident = residents.find(res => res.id === residentId);
    return resident ? `${resident.firstName} ${resident.lastName}` : 'Unknown Resident';
  };

  const sortData = (data) => {
    switch (sortOption) {
      case 'date':
        return data.sort((a, b) => new Date(b.date) - new Date(a.date)); // Change here to sort by newest first
      case 'amount':
        return data.sort((a, b) => parseFloat(b.amount || b.paymentAmount) - parseFloat(a.amount || a.paymentAmount)); // Optionally sort by amount descending
      default:
        return data;
    }
  };

  const filterData = (data) => {
    switch (filterOption) {
      case 'bills':
        return data.filter(item => item.type === 'bill');
      case 'payments':
        return data.filter(item => item.type === 'payment');
      default:
        return data;
    }
  };

  const combinedData = [
    ...payments.map(payment => ({
      ...payment,
      date: payment.paidDate ? payment.paidDate.toDate() : new Date(),
      type: 'payment',
      amount: payment.paymentAmount,
      description: `HOA Dues Payment - ${getResidentName(payment.residentId)}`,
      reconciled: payment.reconciled || false // Add reconciled field
    })),
    ...bills.map(bill => ({
      ...bill,
      date: bill.datePaid ? bill.datePaid.toDate() : new Date(),
      type: 'bill',
      amount: bill.amount,
      description: `Payable to ${bill.payableTo} - Invoice # ${bill.invoiceNumber}`, // Added Invoice # to description
      reconciled: bill.reconciled || false // Add reconciled field
    })),
    ...manualAdjustments.map(adjustment => ({
      ...adjustment,
      date: adjustment.date ? adjustment.date instanceof Date ? adjustment.date : adjustment.date.toDate() : new Date(),
      description: adjustment.description,
      amount: adjustment.amount,
      type: 'manual',
      reconciled: adjustment.reconciled || false // Add reconciled field
    })),
  ];

  const sortedFilteredData = filterData(sortData(combinedData));

  const filteredAndSortedData = sortedFilteredData.filter(entry => {
    if (!searchQuery) return true;
    
    const searchLower = searchQuery.toLowerCase();
    return (
      (entry.description && entry.description.toLowerCase().includes(searchLower)) ||
      (entry.amount && entry.amount.toString().includes(searchLower)) ||
      (entry.date && entry.date.toLocaleDateString().includes(searchLower)) ||
      (entry.type && entry.type.toLowerCase().includes(searchLower))
    );
  });

  const paginatedData = filteredAndSortedData.slice(0, currentPage * entriesPerPage);

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleExport = () => {
    let filteredData = sortedFilteredData.filter(entry => {
      switch (exportOption) {
        case 'month':
          return entry.date >= new Date(new Date().setMonth(new Date().getMonth() - 1));
        case 'sixMonths':
          return entry.date >= new Date(new Date().setMonth(new Date().getMonth() - 6));
        case 'year':
          return entry.date >= new Date(new Date().setFullYear(new Date().getFullYear() - 1));
        case 'custom':
          return startDate && endDate ? (entry.date >= new Date(startDate) && entry.date <= new Date(endDate)) : true;
        default:
          return true;
      }
    });
  
    const doc = new jsPDF({ orientation: 'landscape' });
    const today = new Date();
    const formattedDate = today.toLocaleDateString(undefined, { day: 'numeric', month: 'long', year: 'numeric' });
  
    // Title and date
    doc.text('Ledger Report', 14, 16);
    doc.setFontSize(10);
    doc.text(`Date: ${formattedDate}`, 14, 22);
    doc.text(`Balance: $${balance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, 14, 28);
    doc.setFontSize(12);
  
    // Generate table with ledger entries
    doc.autoTable({
      head: [['Date', 'Description', 'Amount', 'Type', 'Reconciled']],
      body: filteredData.map(entry => [
        entry.date.toLocaleDateString(),
        entry.description,
        `$${parseFloat(entry.amount).toLocaleString()}`,
        entry.type === 'manual' ? (entry.subType === 'credit' ? 'Credit' : 'Debit') : (entry.type === 'payment' ? 'Credit' : 'Debit'),
        entry.reconciled ? 'Yes' : 'No'
      ]),
      theme: 'striped',
      startY: 34,  // Adjust start position to accommodate header information
      headStyles: { fillColor: [26, 29, 36] },
    });
    doc.save('ledger_report.pdf');
  };

  const handleExportDialogOpen = () => {
    setOpenExportDialog(true);
  };

  const handleExportDialogClose = () => {
    setOpenExportDialog(false);
    setExportOption('');
    setStartDate(null);
    setEndDate(null);
  };
  

  const handleManualDialogOpen = () => {
    setOpenManualDialog(true);
  };

  const handleManualDialogClose = () => {
    setOpenManualDialog(false);
    setManualAmount('');
    setManualDescription('');
    setManualType('');
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleManualAddition = async () => {
    try {
      setLoading(true);
      const adjustmentData = {
        description: manualDescription,
        amount: parseFloat(manualAmount),
        type: 'manual',
        subType: manualType,
        date: new Date(),
        reconciled: false
      };
  
      const manualAdjustmentsCollection = collection(db, 'manualLedgerAdjustments');
      const docRef = await addDoc(manualAdjustmentsCollection, adjustmentData);
      setManualAdjustments([...manualAdjustments, { ...adjustmentData, id: docRef.id }]);
  
      calculateBalance(bills, payments, [...manualAdjustments, { ...adjustmentData, id: docRef.id }]);
      handleManualDialogClose();
      showSnackbar('Manual adjustment added successfully');
    } catch (error) {
      console.error("Error adding manual adjustment: ", error);
      showSnackbar('Error adding manual adjustment');
    } finally {
      setLoading(false);
    }
  };
  
  const handleReconciledChange = async (entry, checked) => {
    try {
      const entryDoc = doc(db, entry.type === 'payment' ? 'payments' : entry.type === 'bill' ? 'bills' : 'manualLedgerAdjustments', entry.id);
      await updateDoc(entryDoc, { reconciled: checked });
      const updatedEntries = combinedData.map(e => e.id === entry.id ? { ...e, reconciled: checked } : e);
      const updatedBills = updatedEntries.filter(e => e.type === 'bill');
      const updatedPayments = updatedEntries.filter(e => e.type === 'payment');
      const updatedManualAdjustments = updatedEntries.filter(e => e.type === 'manual');
      setBills(updatedBills);
      setPayments(updatedPayments);
      setManualAdjustments(updatedManualAdjustments);
    } catch (error) {
      console.error("Error updating reconciled status: ", error);
    }
  };

  const handleDeleteManualAdjustment = async () => {
    try {
      setLoading(true);
      const adjustmentDoc = doc(db, 'manualLedgerAdjustments', currentEntry.id);
      await deleteDoc(adjustmentDoc);
      
      const updatedAdjustments = manualAdjustments.filter(adj => adj.id !== currentEntry.id);
      setManualAdjustments(updatedAdjustments);
      calculateBalance(bills, payments, updatedAdjustments);
      
      handleDeleteDialogClose();
      showSnackbar('Entry deleted successfully');
    } catch (error) {
      console.error("Error deleting adjustment: ", error);
      showSnackbar('Error deleting entry');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteDialogOpen = (entry) => {
    setCurrentEntry(entry);
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
    setCurrentEntry(null);
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 4 }}>
        <Box 
          sx={{ 
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'flex-start', md: 'center' },
            justifyContent: 'space-between',
            mb: 4,
            gap: 2
          }}
        >
          <Box>
            <Typography 
              variant="h4" 
              component="h1" 
              sx={{ 
                fontWeight: 700,
                color: theme => theme.palette.text.primary,
                mb: 1
              }}
            >
              Financial Ledger
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ 
                color: theme => theme.palette.text.secondary,
                maxWidth: 600,
                mb: 3
              }}
            >
              Track and manage financial transactions, including payments, bills, and manual adjustments.
            </Typography>
            
            <Card 
              elevation={0}
              sx={{ 
                display: 'inline-flex',
                alignItems: 'center',
                bgcolor: theme => alpha(theme.palette.primary.main, 0.03),
                border: '1px solid',
                borderColor: theme => alpha(theme.palette.primary.main, 0.08),
                borderRadius: 2,
                px: 2,
                py: 1.5,
                maxWidth: 'fit-content'
              }}
            >
              <Stack direction="row" spacing={1.5} alignItems="center">
                <Typography 
                  variant="body1" 
                  sx={{ 
                    color: theme => theme.palette.text.secondary,
                    fontWeight: 500
                  }}
                >
                  Balance
                </Typography>
                {showBalance ? (
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      color: theme => theme.palette.text.primary,
                      fontWeight: 600,
                      lineHeight: 1
                    }}
                  >
                    ${balance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </Typography>
                ) : (
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      color: theme => theme.palette.text.primary,
                      letterSpacing: 2,
                      lineHeight: 1
                    }}
                  >
                    ••••••
                  </Typography>
                )}
                <Tooltip title={showBalance ? "Hide balance" : "Show balance"}>
                  <IconButton
                    onClick={() => setShowBalance(!showBalance)}
                    size="small"
                    sx={{
                      color: 'text.secondary',
                      '&:hover': {
                        color: 'primary.main',
                        bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
                      }
                    }}
                  >
                    {showBalance ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
                  </IconButton>
                </Tooltip>
              </Stack>
            </Card>
          </Box>
          
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} width={{ xs: '100%', sm: 'auto' }}>
            <Button
              variant="outlined"
              startIcon={<DownloadIcon />}
              onClick={handleExportDialogOpen}
              sx={{
                borderRadius: 2,
                textTransform: 'none',
                px: 3,
                py: 1.5,
                borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                color: 'text.primary',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                }
              }}
            >
              Export Ledger
            </Button>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleManualDialogOpen}
              sx={{
                borderRadius: 2,
                textTransform: 'none',
                px: 3,
                py: 1.5,
                bgcolor: '#2d3748',
                color: '#fff',
                boxShadow: 2,
                '&:hover': {
                  bgcolor: '#3a4a61',
                }
              }}
            >
              Add Manual Entry
            </Button>
          </Stack>
        </Box>

        <Card 
          elevation={0} 
          sx={{ 
            mb: 4, 
            bgcolor: theme => alpha(theme.palette.primary.main, 0.03),
            border: '1px solid',
            borderColor: theme => alpha(theme.palette.primary.main, 0.08),
            borderRadius: 3
          }}
        >
          <CardContent sx={{ py: 2 }}>
            <Stack 
              direction={{ xs: 'column', sm: 'row' }} 
              spacing={2} 
              alignItems="center" 
              justifyContent="space-between"
            >
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search ledger entries..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: 'text.secondary' }} />
                    </InputAdornment>
                  ),
                  endAdornment: searchQuery && (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => setSearchQuery('')}
                        sx={{ color: 'text.secondary' }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  maxWidth: 400,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                  }
                }}
              />

              <Stack 
                direction={{ xs: 'column', sm: 'row' }} 
                spacing={2}
                width={{ xs: '100%', sm: 'auto' }}
              >
                <FormControl 
                  size="small" 
                  sx={{ 
                    minWidth: 150,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 2,
                    }
                  }}
                >
                  <InputLabel>Sort By</InputLabel>
                  <Select
                    value={sortOption}
                    onChange={(e) => setSortOption(e.target.value)}
                    label="Sort By"
                    startAdornment={<SortIcon sx={{ ml: 1, mr: 1, opacity: 0.5 }} />}
                  >
                    <MenuItem value="date">Date</MenuItem>
                    <MenuItem value="amount">Amount</MenuItem>
                  </Select>
                </FormControl>

                <FormControl 
                  size="small" 
                  sx={{ 
                    minWidth: 150,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 2,
                    }
                  }}
                >
                  <InputLabel>Filter</InputLabel>
                  <Select
                    value={filterOption}
                    onChange={(e) => setFilterOption(e.target.value)}
                    label="Filter"
                    startAdornment={<FilterListIcon sx={{ ml: 1, mr: 1, opacity: 0.5 }} />}
                  >
                    <MenuItem value="all">All Entries</MenuItem>
                    <MenuItem value="bills">Bills Only</MenuItem>
                    <MenuItem value="payments">Payments Only</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Stack>
          </CardContent>
        </Card>

        <Alert 
          severity="info" 
          sx={{ 
            mb: 3,
            borderRadius: 2,
            '& .MuiAlert-icon': {
              color: 'primary.main'
            }
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 500, mb: 0.5 }}>
            Tips:
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            • Use the search bar to find specific entries
            <br />
            • Sort by date or amount using the dropdown
            <br />
            • Filter to show only bills or payments
            <br />
            • Click the eye icon to show/hide the current balance
          </Typography>
        </Alert>

        <Card elevation={2}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600 }}>Date</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Description</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Amount</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Type</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Reconciled</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  // Loading skeleton animation
                  [...Array(8)].map((_, index) => (
                    <TableRow key={`skeleton-${index}`}>
                      <TableCell>
                        <Skeleton 
                          animation="wave" 
                          height={24} 
                          width={100} 
                          sx={{ 
                            bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
                            borderRadius: 1
                          }} 
                        />
                      </TableCell>
                      <TableCell>
                        <Skeleton 
                          animation="wave" 
                          height={24} 
                          width="90%" 
                          sx={{ 
                            bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
                            borderRadius: 1
                          }} 
                        />
                      </TableCell>
                      <TableCell>
                        <Skeleton 
                          animation="wave" 
                          height={24} 
                          width={100} 
                          sx={{ 
                            bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
                            borderRadius: 1
                          }} 
                        />
                      </TableCell>
                      <TableCell>
                        <Skeleton 
                          animation="wave" 
                          height={24} 
                          width={80} 
                          sx={{ 
                            bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
                            borderRadius: 1
                          }} 
                        />
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          <Skeleton 
                            animation="wave" 
                            variant="circular" 
                            width={32} 
                            height={32} 
                            sx={{ 
                              bgcolor: theme => alpha(theme.palette.primary.main, 0.08)
                            }} 
                          />
                          <Skeleton 
                            animation="wave" 
                            variant="circular" 
                            width={32} 
                            height={32} 
                            sx={{ 
                              bgcolor: theme => alpha(theme.palette.primary.main, 0.08)
                            }} 
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  paginatedData.map((entry) => (
                    <TableRow 
                      key={entry.id}
                      sx={{ 
                        '&:hover': { 
                          bgcolor: theme => alpha(theme.palette.primary.main, 0.04)
                        }
                      }}
                    >
                      <TableCell>{entry.date ? entry.date.toLocaleDateString() : 'Pending'}</TableCell>
                      <TableCell>{entry.description || 'Pending'}</TableCell>
                      <CreditCell entry={entry}>
                        {entry.amount ? 
                          `$${parseFloat(entry.amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}` : 
                          'Pending'
                        }
                      </CreditCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            px: 1,
                            py: 0.5,
                            borderRadius: 1,
                            bgcolor: entry.type === 'payment' || (entry.type === 'manual' && entry.subType === 'credit')
                              ? 'success.soft'
                              : 'error.soft',
                            color: entry.type === 'payment' || (entry.type === 'manual' && entry.subType === 'credit')
                              ? 'success.main'
                              : 'error.main',
                          }}
                        >
                          <Typography variant="body2">
                            {entry.type === 'manual' 
                              ? (entry.subType === 'credit' ? 'Credit' : 'Debit')
                              : (entry.type === 'payment' ? 'Credit' : 'Debit')}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={entry.reconciled}
                          onChange={(e) => handleReconciledChange(entry, e.target.checked)}
                          color="primary"
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        {entry.type === 'manual' && (
                          <Tooltip title="Delete Entry">
                            <IconButton
                              size="small"
                              onClick={() => handleDeleteDialogOpen(entry)}
                              sx={{ 
                                color: 'error.main',
                                '&:hover': {
                                  bgcolor: theme => alpha(theme.palette.error.main, 0.04),
                                }
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>

        {paginatedData.length < filteredAndSortedData.length && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Button
              onClick={handleLoadMore}
              variant="outlined"
              sx={{
                borderRadius: 2,
                textTransform: 'none',
                px: 4,
                borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                color: 'text.primary',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                }
              }}
            >
              Load More
            </Button>
          </Box>
        )}

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        )}

        <Dialog 
          open={openExportDialog} 
          onClose={handleExportDialogClose}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 2
            }
          }}
        >
          <DialogTitle sx={{ pb: 1 }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
              Export Ledger
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ pt: 2 }}>
            <Stack spacing={3}>
              <FormControl fullWidth>
                <InputLabel>Export Range</InputLabel>
                <Select
                  value={exportOption}
                  onChange={(e) => setExportOption(e.target.value)}
                  label="Export Range"
                >
                  <MenuItem value="month">Last Month</MenuItem>
                  <MenuItem value="sixMonths">Last 6 Months</MenuItem>
                  <MenuItem value="year">Last Year</MenuItem>
                  <MenuItem value="custom">Custom Range</MenuItem>
                  <MenuItem value="all">All Time</MenuItem>
                </Select>
              </FormControl>

              {exportOption === 'custom' && (
                <>
                  <TextField
                    label="Start Date"
                    type="date"
                    value={startDate || ''}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                  <TextField
                    label="End Date"
                    type="date"
                    value={endDate || ''}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </>
              )}
            </Stack>
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 2 }}>
            <Button 
              onClick={handleExportDialogClose}
              variant="outlined"
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                color: 'text.primary',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                }
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleExport}
              variant="contained"
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                boxShadow: 2
              }}
            >
              Export
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog 
          open={openManualDialog} 
          onClose={handleManualDialogClose}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 2
            }
          }}
        >
          <DialogTitle sx={{ pb: 1 }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
              Add Manual Entry
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ pt: 2 }}>
            <Stack spacing={3}>
              <TextField
                label="Description"
                fullWidth
                value={manualDescription}
                onChange={(e) => setManualDescription(e.target.value)}
              />
              <TextField
                label="Amount"
                fullWidth
                value={manualAmount}
                onChange={(e) => setManualAmount(e.target.value)}
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
              <FormControl fullWidth>
                <InputLabel>Type</InputLabel>
                <Select
                  value={manualType}
                  onChange={(e) => setManualType(e.target.value)}
                  label="Type"
                >
                  <MenuItem value="credit">Credit</MenuItem>
                  <MenuItem value="debit">Debit</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 2 }}>
            <Button 
              onClick={handleManualDialogClose}
              variant="outlined"
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                color: 'text.primary',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                }
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleManualAddition}
              variant="contained"
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                boxShadow: 2
              }}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog 
          open={openDeleteDialog} 
          onClose={handleDeleteDialogClose}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 2
            }
          }}
        >
          <DialogTitle sx={{ pb: 1 }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
              Confirm Delete
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ pt: 2 }}>
            <Typography variant="body1" color="text.secondary">
              Are you sure you want to delete this manual entry? This action cannot be undone.
              {currentEntry && (
                <Box sx={{ mt: 2 }}>
                  <Typography component="div" variant="body2" color="text.primary">
                    <strong>Description:</strong> {currentEntry.description}
                  </Typography>
                  <Typography component="div" variant="body2" color="text.primary">
                    <strong>Amount:</strong> ${parseFloat(currentEntry.amount).toLocaleString()}
                  </Typography>
                  <Typography component="div" variant="body2" color="text.primary">
                    <strong>Type:</strong> {currentEntry.subType === 'credit' ? 'Credit' : 'Debit'}
                  </Typography>
                </Box>
              )}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 2 }}>
            <Button 
              onClick={handleDeleteDialogClose}
              variant="outlined"
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                color: 'text.primary',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                }
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteManualAdjustment}
              variant="contained"
              color="error"
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                boxShadow: 2
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar 
          open={openSnackbar} 
          autoHideDuration={6000} 
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={handleSnackbarClose} 
            severity="info" 
            sx={{ 
              width: '100%',
              borderRadius: 2
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default Ledger;
