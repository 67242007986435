import React, { useState, useEffect } from 'react';
import { collection, getDocs, updateDoc, doc as firestoreDoc } from 'firebase/firestore';
import { pdf } from '@react-pdf/renderer';
import { db, storage } from '../firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { 
  Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Typography, Box, CircularProgress, Checkbox, Dialog, InputAdornment, DialogActions, 
  DialogContent, DialogTitle, TextField, Tooltip, Container, Card, CardContent, Stack,
  IconButton, Divider, alpha, Snackbar, Alert
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import InvoicePDF from './InvoicePDF';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import WarningIcon from '@mui/icons-material/Warning';
import ReceiptIcon from '@mui/icons-material/Receipt';

const DialogProps = {
  PaperProps: {
    sx: {
      borderRadius: 2,
      zIndex: 1200
    }
  },
  sx: {
    '& .MuiBackdrop-root': {
      zIndex: 1100
    }
  }
};

const Invoices = () => {
  const [residents, setResidents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedForEmail, setSelectedForEmail] = useState({});
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [lateEmailDialogOpen, setLateEmailDialogOpen] = useState(false);
  const [customInvoiceOpen, setCustomInvoiceOpen] = useState(false);
  const [selectedResident, setSelectedResident] = useState(null);
  const [emailSending, setEmailSending] = useState(false);
  const [dueDate, setDueDate] = useState(new Date());
  const [customDueDate, setCustomDueDate] = useState(new Date());
  const [amount, setAmount] = useState(300);
  const [customAmount, setCustomAmount] = useState(300);
  const [progress, setProgress] = useState(0);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [resetConfirmOpen, setResetConfirmOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    const fetchResidents = async () => {
      const querySnapshot = await getDocs(collection(db, 'residents'));
      const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Sort the residents by lotNumber in ascending order
      const sortedData = data.sort((a, b) => Number(a.lotNumber) - Number(b.lotNumber));

      setResidents(sortedData);
      setSelectedForEmail(sortedData.reduce((acc, resident) => ({ ...acc, [resident.id]: false }), {}));
      setLoading(false);
    };

    const timeoutId = setTimeout(() => {
      fetchResidents();
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  const toggleEmailSelection = (id) => {
    setSelectedForEmail(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const toggleAllEmailSelections = () => {
    const allSelected = Object.values(selectedForEmail).every(Boolean);
    const newSelections = Object.keys(selectedForEmail).reduce((acc, id) => ({ ...acc, [id]: !allSelected }), {});
    setSelectedForEmail(newSelections);
  };

  const handleOpenEmailDialog = () => {
    setEmailDialogOpen(true);
  };

  const handleCloseEmailDialog = () => {
    setEmailDialogOpen(false);
  };

  const handleOpenLateEmailDialog = () => {
    if (!Object.values(selectedForEmail).some(Boolean)) {
      setSnackbar({
        open: true,
        message: 'Please select at least one resident',
        severity: 'warning'
      });
      return;
    }
    setLateEmailDialogOpen(true);
  };

  const handleCloseLateEmailDialog = () => {
    setLateEmailDialogOpen(false);
    setProgress(0);
  };

  const handleCloseSuccessDialog = () => {
    setSuccessDialogOpen(false);
  };

  const confirmEmailDues = async () => {
    setEmailSending(true);
    const emailsToSend = residents.filter(resident => selectedForEmail[resident.id]);

    setProgress(0);

    for (let i = 0; i < emailsToSend.length; i++) {
      const resident = emailsToSend[i];
      const doc = <InvoicePDF resident={resident} dueDate={formatDate(dueDate)} amount={amount} />;
      const blob = await pdf(doc).toBlob();
      const dateStr = formatDate(new Date());
      const filename = `Invoice_${resident.lastName}_${dateStr}.pdf`;
      const storageRef = ref(storage, `invoices/${filename}`);
      await uploadBytes(storageRef, blob);
      const url = await getDownloadURL(storageRef);

      await fetch('https://us-central1-hoa-management24.cloudfunctions.net/sendDueReminders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: resident.email,
          pdfUrl: url,
          lastName: resident.lastName
        })
      });

      setProgress(i + 1);

      // Update emailSent status
      await updateDoc(firestoreDoc(db, 'residents', resident.id), { emailSent: true });
      setResidents(prevResidents =>
        prevResidents.map(r => r.id === resident.id ? { ...r, emailSent: true } : r)
      );
    }

    setEmailSending(false);
    setEmailDialogOpen(false);
    setSuccessDialogOpen(true);
  };

  const confirmLateEmailDues = async () => {
    setEmailSending(true);
    const emailsToSend = residents.filter(resident => selectedForEmail[resident.id]);
    setProgress(0);

    try {
      for (let i = 0; i < emailsToSend.length; i++) {
        const resident = emailsToSend[i];
        const doc = <InvoicePDF resident={resident} dueDate={formatDate(dueDate)} amount={amount} />;
        const blob = await pdf(doc).toBlob();
        const dateStr = formatDate(new Date());
        const filename = `LateNotice_${resident.lastName}_${dateStr}.pdf`;
        const storageRef = ref(storage, `invoices/${filename}`);
        await uploadBytes(storageRef, blob);
        const url = await getDownloadURL(storageRef);

        await fetch('https://us-central1-hoa-management24.cloudfunctions.net/sendLatePaymentNotices', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: resident.email,
            pdfUrl: url,
            lastName: resident.lastName,
            amount: amount,
            dueDate: formatDate(dueDate)
          })
        });

        setProgress(i + 1);

        await updateDoc(firestoreDoc(db, 'residents', resident.id), { emailSent: true });
        setResidents(prevResidents =>
          prevResidents.map(r => r.id === resident.id ? { ...r, emailSent: true } : r)
        );
      }

      setSnackbar({
        open: true,
        message: 'Late payment notices sent successfully',
        severity: 'success'
      });
      handleCloseLateEmailDialog();
      setSuccessDialogOpen(true);
    } catch (error) {
      console.error('Error sending late notices:', error);
      setSnackbar({
        open: true,
        message: 'Error sending late notices. Please try again.',
        severity: 'error'
      });
    } finally {
      setEmailSending(false);
      setProgress(0);
    }
  };

  const openCustomInvoiceDialog = (resident) => {
    setSelectedResident(resident);
    setCustomDueDate(new Date());
    setCustomAmount(300);
    setCustomInvoiceOpen(true);
  };

  const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [month, day, year].join('/');
  };

  const downloadCustomInvoice = async () => {
    const doc = <InvoicePDF resident={selectedResident} dueDate={formatDate(customDueDate)} amount={customAmount} />;
    const asBlob = await pdf(doc).toBlob();
    const dateStr = formatDate(new Date());
    const filename = `Invoice_${selectedResident.lastName}_${dateStr}.pdf`;
    const link = document.createElement('a');
    link.href = URL.createObjectURL(asBlob);
    link.download = filename;
    link.click();
    setCustomInvoiceOpen(false);
  };

  const handleResetAllEmailStatus = async () => {
    setResetConfirmOpen(true);
  };

  const confirmReset = async () => {
    try {
      const updatedResidents = await Promise.all(
        residents.map(async (resident) => {
          const residentDoc = firestoreDoc(db, 'residents', resident.id);
          await updateDoc(residentDoc, { emailSent: false });
          return { ...resident, emailSent: false };
        })
      );
      setResidents(updatedResidents);
      setResetConfirmOpen(false);
    } catch (error) {
      console.error("Error resetting email statuses: ", error);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>Loading Invoice Generator...</Typography>
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="xl">
        <Box sx={{ py: 4 }}>
          <Box 
            sx={{ 
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: { xs: 'flex-start', md: 'center' },
              justifyContent: 'space-between',
              mb: 4,
              gap: 2
            }}
          >
            <Box>
              <Typography 
                variant="h4" 
                component="h1" 
                sx={{ 
                  fontWeight: 700,
                  color: theme => theme.palette.text.primary,
                  mb: 1
                }}
              >
                Invoice Management
              </Typography>
              <Typography 
                variant="body1" 
                sx={{ 
                  color: theme => theme.palette.text.secondary,
                  maxWidth: 600
                }}
              >
                Generate and send invoices to residents, track payment status, and manage due notices.
              </Typography>
            </Box>
            
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} width={{ xs: '100%', sm: 'auto' }}>
              <Button
                variant="contained"
                startIcon={<SendIcon />}
                onClick={handleOpenEmailDialog}
                disabled={!Object.values(selectedForEmail).some(Boolean)}
                sx={{
                  borderRadius: 2,
                  textTransform: 'none',
                  px: 3,
                  py: 1.5,
                  bgcolor: '#2d3748',
                  color: '#fff',
                  boxShadow: 2,
                  '&:hover': {
                    bgcolor: '#3a4a61',
                  },
                  '&:disabled': {
                    bgcolor: theme => alpha('#2d3748', 0.12),
                    color: theme => alpha('#2d3748', 0.26)
                  }
                }}
              >
                Send Invoices
              </Button>
              <Button
                variant="outlined"
                startIcon={<WarningIcon />}
                onClick={handleOpenLateEmailDialog}
                disabled={!Object.values(selectedForEmail).some(Boolean)}
                sx={{
                  borderRadius: 2,
                  textTransform: 'none',
                  px: 3,
                  py: 1.5,
                  borderColor: theme => alpha(theme.palette.error.main, 0.5),
                  color: 'error.main',
                  '&:hover': {
                    borderColor: 'error.main',
                    bgcolor: theme => alpha(theme.palette.error.main, 0.04),
                  },
                  '&:disabled': {
                    borderColor: theme => alpha(theme.palette.error.main, 0.12),
                    color: theme => alpha(theme.palette.error.main, 0.26)
                  }
                }}
              >
                Send Late Notices
              </Button>
            </Stack>
          </Box>

          <Card 
            elevation={0} 
            sx={{ 
              mb: 4, 
              bgcolor: theme => alpha(theme.palette.primary.main, 0.03),
              border: '1px solid',
              borderColor: theme => alpha(theme.palette.primary.main, 0.08),
              borderRadius: 3
            }}
          >
            <CardContent sx={{ py: 2 }}>
              <Stack 
                direction={{ xs: 'column', sm: 'row' }} 
                spacing={2} 
                alignItems="center" 
                justifyContent="space-between"
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: 'text.secondary',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}
                  >
                    <Box
                      component="span"
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                        bgcolor: theme => alpha(theme.palette.primary.main, 0.1),
                        color: 'primary.main',
                        fontWeight: 500
                      }}
                    >
                      {Object.values(selectedForEmail).filter(Boolean).length}
                    </Box>
                    residents selected
                  </Typography>
                  {Object.values(selectedForEmail).filter(Boolean).length === 0 && (
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: 'text.secondary',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5
                      }}
                    >
                      • Select residents to send invoices
                    </Typography>
                  )}
                </Box>
                <Button
                  variant="outlined"
                  startIcon={<RestartAltIcon />}
                  onClick={handleResetAllEmailStatus}
                  sx={{
                    borderRadius: 2,
                    textTransform: 'none',
                    px: 3,
                    py: 1.5,
                    borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                    color: 'text.primary',
                    '&:hover': {
                      borderColor: 'primary.main',
                      bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                    }
                  }}
                >
                  Reset Email Status
                </Button>
              </Stack>
            </CardContent>
          </Card>

          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
              <CircularProgress />
            </Box>
          ) : (
            <Card elevation={2}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={Object.values(selectedForEmail).every(Boolean)}
                          indeterminate={Object.values(selectedForEmail).some(Boolean) && !Object.values(selectedForEmail).every(Boolean)}
                          onChange={toggleAllEmailSelections}
                        />
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Lot</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Email</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {residents.map((resident) => (
                      <TableRow 
                        key={resident.id}
                        sx={{ 
                          '&:hover': { 
                            bgcolor: theme => alpha(theme.palette.primary.main, 0.04)
                          }
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedForEmail[resident.id] || false}
                            onChange={() => toggleEmailSelection(resident.id)}
                          />
                        </TableCell>
                        <TableCell>{resident.lotNumber}</TableCell>
                        <TableCell>{`${resident.firstName} ${resident.lastName}`}</TableCell>
                        <TableCell>{resident.email}</TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              px: 1,
                              py: 0.5,
                              borderRadius: 1,
                              bgcolor: resident.emailSent ? 'success.soft' : 'warning.soft',
                              color: resident.emailSent ? 'success.main' : 'warning.main',
                            }}
                          >
                            <Typography variant="body2">
                              {resident.emailSent ? 'Sent' : 'Pending'}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Generate Custom Invoice">
                            <IconButton
                              size="small"
                              onClick={() => openCustomInvoiceDialog(resident)}
                              sx={{ 
                                color: 'primary.main',
                                '&:hover': {
                                  bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                                }
                              }}
                            >
                              <ReceiptIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          )}
        </Box>

        <Dialog 
          open={emailDialogOpen} 
          onClose={handleCloseEmailDialog}
          maxWidth="sm"
          fullWidth
          {...DialogProps}
        >
          <DialogTitle sx={{ pb: 1 }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
              Send Invoices
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ pt: 2 }}>
            <Stack spacing={3}>
              <TextField
                label="Amount"
                type="number"
                value={amount}
                onChange={(e) => setAmount(Number(e.target.value))}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                fullWidth
              />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                  Due Date
                </Typography>
                <DatePicker
                  value={dueDate}
                  onChange={(newValue) => setDueDate(newValue)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: "small",
                      sx: {
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: '#27272A',
                          '& fieldset': {
                            borderColor: '#3F3F46',
                          },
                          '&:hover fieldset': {
                            borderColor: '#4F46E5',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#4F46E5',
                          },
                          '& input': {
                            color: '#FFFFFF',
                          }
                        }
                      }
                    }
                  }}
                />
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 2 }}>
            <Button 
              onClick={handleCloseEmailDialog}
              variant="outlined"
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                color: 'text.primary',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                }
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={confirmEmailDues}
              variant="contained"
              disabled={emailSending}
              startIcon={emailSending ? <CircularProgress size={20} /> : <SendIcon />}
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                boxShadow: 2,
                '&:disabled': {
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.12),
                  color: theme => alpha(theme.palette.primary.main, 0.26)
                }
              }}
            >
              {emailSending ? 'Sending...' : 'Send'}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={successDialogOpen}
          onClose={handleCloseSuccessDialog}
          PaperProps={{
            sx: {
              borderRadius: 2
            }
          }}
        >
          <DialogContent>
            <Box sx={{ textAlign: 'center', py: 2 }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                Success!
              </Typography>
              <Typography variant="body1" color="text.secondary">
                All selected invoices have been sent successfully.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 2 }}>
            <Button 
              onClick={handleCloseSuccessDialog}
              variant="contained"
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                boxShadow: 2
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog 
          open={customInvoiceOpen} 
          onClose={() => setCustomInvoiceOpen(false)}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 2
            }
          }}
        >
          <DialogTitle sx={{ pb: 1 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
                Generate Custom Invoice
              </Typography>
              <IconButton
                edge="end"
                onClick={() => setCustomInvoiceOpen(false)}
                aria-label="close"
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ pt: 2 }}>
            <Stack spacing={3}>
              {selectedResident && (
                <Typography variant="body1" color="text.secondary">
                  Generating invoice for: {selectedResident.firstName} {selectedResident.lastName}
                </Typography>
              )}
              <TextField
                label="Amount"
                type="number"
                value={customAmount}
                onChange={(e) => setCustomAmount(Number(e.target.value))}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                fullWidth
              />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                  Due Date
                </Typography>
                <DatePicker
                  value={customDueDate}
                  onChange={(newValue) => setCustomDueDate(newValue)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: "small",
                      sx: {
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: '#27272A',
                          '& fieldset': {
                            borderColor: '#3F3F46',
                          },
                          '&:hover fieldset': {
                            borderColor: '#4F46E5',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#4F46E5',
                          },
                          '& input': {
                            color: '#FFFFFF',
                          }
                        }
                      }
                    }
                  }}
                />
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 2 }}>
            <Button 
              onClick={() => setCustomInvoiceOpen(false)}
              variant="outlined"
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                color: 'text.primary',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                }
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={downloadCustomInvoice}
              variant="contained"
              startIcon={<DownloadIcon />}
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                boxShadow: 2
              }}
            >
              Download
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={resetConfirmOpen}
          onClose={() => setResetConfirmOpen(false)}
          PaperProps={{
            sx: {
              borderRadius: 2
            }
          }}
        >
          <DialogTitle sx={{ pb: 1 }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
              Confirm Reset
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ pt: 2 }}>
            <Typography variant="body1" color="text.secondary">
              Are you sure you want to reset the email status for all residents? This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 2 }}>
            <Button 
              onClick={() => setResetConfirmOpen(false)}
              variant="outlined"
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                color: 'text.primary',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                }
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={confirmReset}
              variant="contained"
              color="error"
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                boxShadow: 2,
                '&:hover': {
                  bgcolor: 'error.dark'
                }
              }}
            >
              Reset All
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog 
          open={lateEmailDialogOpen} 
          onClose={handleCloseLateEmailDialog}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 2
            }
          }}
        >
          <DialogTitle sx={{ pb: 1 }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
              Send Late Payment Notices
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ pt: 2 }}>
            <Stack spacing={3}>
              <TextField
                label="Amount"
                type="number"
                value={amount}
                onChange={(e) => setAmount(Number(e.target.value))}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                fullWidth
              />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                  Due Date
                </Typography>
                <DatePicker
                  value={dueDate}
                  onChange={(newValue) => setDueDate(newValue)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: "small",
                      sx: {
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: '#27272A',
                          '& fieldset': {
                            borderColor: '#3F3F46',
                          },
                          '&:hover fieldset': {
                            borderColor: '#4F46E5',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#4F46E5',
                          },
                          '& input': {
                            color: '#FFFFFF',
                          }
                        }
                      }
                    }
                  }}
                />
              </Box>
              {progress > 0 && (
                <Box sx={{ width: '100%', mt: 2 }}>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                    Sending notices... ({progress} of {Object.values(selectedForEmail).filter(Boolean).length})
                  </Typography>
                  <Box
                    sx={{
                      height: 4,
                      borderRadius: 2,
                      bgcolor: theme => alpha(theme.palette.primary.main, 0.1),
                      overflow: 'hidden'
                    }}
                  >
                    <Box
                      sx={{
                        height: '100%',
                        width: `${(progress / Object.values(selectedForEmail).filter(Boolean).length) * 100}%`,
                        bgcolor: 'primary.main',
                        transition: 'width 0.3s ease-in-out'
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Stack>
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 2 }}>
            <Button 
              onClick={handleCloseLateEmailDialog}
              variant="outlined"
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                color: 'text.primary',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                }
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={confirmLateEmailDues}
              variant="contained"
              disabled={emailSending}
              startIcon={emailSending ? <CircularProgress size={20} /> : <SendIcon />}
              sx={{ 
                textTransform: 'none',
                minWidth: 100,
                borderRadius: 2,
                boxShadow: 2,
                bgcolor: 'error.main',
                '&:hover': {
                  bgcolor: 'error.dark'
                },
                '&:disabled': {
                  bgcolor: theme => alpha(theme.palette.error.main, 0.12),
                  color: theme => alpha(theme.palette.error.main, 0.26)
                }
              }}
            >
              {emailSending ? 'Sending...' : 'Send Late Notices'}
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          sx={{
            '& .MuiSnackbarContent-root': {
              bgcolor: theme => theme.palette.mode === 'dark' ? '#27272A' : '#FFFFFF',
              color: theme => theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
              boxShadow: theme => theme.palette.mode === 'dark' 
                ? '0 4px 12px rgba(0, 0, 0, 0.4)' 
                : '0 4px 12px rgba(0, 0, 0, 0.1)',
              borderRadius: 2,
              border: theme => theme.palette.mode === 'dark'
                ? '1px solid #3F3F46'
                : '1px solid #E5E7EB',
            }
          }}
        >
          <Alert 
            onClose={handleCloseSnackbar} 
            severity={snackbar.severity}
            sx={{ 
              width: '100%',
              '& .MuiAlert-icon': {
                color: theme => theme.palette.mode === 'dark' ? '#FFFFFF' : 'inherit'
              }
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </LocalizationProvider>
  );
};

export default Invoices;
