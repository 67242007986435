import React, { useState, useEffect, useCallback, useRef } from 'react';
import { format } from 'date-fns';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { 
  Button, Dialog, DialogActions, DialogContent, DialogTitle, 
  TextField, Table, TableBody, TableCell, TableHead, TableRow, Box, Container,
  IconButton, Typography, Checkbox, 
  Card, CardContent, Divider, Stack, Tooltip, TableContainer, alpha,
  CircularProgress, InputAdornment, Alert, Snackbar, Skeleton
} from '@mui/material';
import { collection, getDocs, query, where, doc, getDoc, setDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { db } from '../firebaseConfig';

const Payments = () => {
  const pressTimer = useRef(null);
  const paymentAmountRef = useRef(null);
  const [residents, setResidents] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [selectedResident, setSelectedResident] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState('300'); // Default payment amount
  const [checkNumber, setCheckNumber] = useState('');
  const [period, setPeriod] = useState({ start: new Date(), end: new Date() });
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortField, setSortField] = useState('lotNumber');
  const [sortDirection, setSortDirection] = useState('asc');
  const [clearConfirmation, setClearConfirmation] = useState('');
  const [clearError, setClearError] = useState(false);

  const initializePeriod = useCallback((year, periodHalf) => {
    const start = new Date(year, periodHalf === 1 ? 0 : 6, 1);
    const end = new Date(year, periodHalf === 1 ? 5 : 11, 30);
    setPeriod({ start, end });
    fetchResidentsAndPayments(start, end, true);
  }, []);

  useEffect(() => {
    const today = new Date();
    initializePeriod(today.getFullYear(), today.getMonth() < 6 ? 1 : 2);
  }, [initializePeriod]);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleMouseDown = () => {
    pressTimer.current = window.setTimeout(() => {
      setOpenDialog(true);
    }, 2000);
  };

  const handleMouseUp = () => {
    clearTimeout(pressTimer.current);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setClearConfirmation('');
    setClearError(false);
  };

  const handlePaymentDialogClose = () => {
    setOpenPaymentDialog(false);
  };

  const handleClearConfirmationChange = (e) => {
    setClearConfirmation(e.target.value);
    setClearError(false);
  };

  const clearPaymentsForPeriod = async () => {
    if (clearConfirmation !== 'CLEAR') {
      setClearError(true);
      return;
    }

    try {
      setLoading(true);
      const paymentsQuery = query(collection(db, "payments"), where("periodStart", "==", period.start), where("periodEnd", "==", period.end));
      const paymentsSnapshot = await getDocs(paymentsQuery);
      const deletePromises = paymentsSnapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deletePromises);
      showSnackbar("All payments for the period have been cleared.");
      handleDialogClose();
      fetchResidentsAndPayments(period.start, period.end);
    } catch (error) {
      console.error("Error clearing payments:", error);
      showSnackbar("Error clearing payments. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const createMissingPayments = async (start, end) => {
    if (!(start instanceof Date) || !(end instanceof Date) || isNaN(start.getTime()) || isNaN(end.getTime())) {
      console.error('Invalid dates provided:', { start, end });
      return;
    }

    setLoading(true); 

    const residentsCollection = collection(db, "residents");
    const residentsSnapshot = await getDocs(residentsCollection);
    let createdCount = 0;

    for (const residentDoc of residentsSnapshot.docs) {
      const paymentId = `${residentDoc.id}-${format(start, 'yyyy-MM')}`;
      const paymentRef = doc(db, "payments", paymentId);
      const paymentSnap = await getDoc(paymentRef);

      if (!paymentSnap.exists()) {
        await setDoc(paymentRef, {
          residentId: residentDoc.id,
          isPaid: false,
          paidDate: null,
          periodStart: start,
          periodEnd: end,
          createdAutomatically: true,
          paymentAmount: '300', // Default payment amount
          checkNumber: ''
        });
        createdCount++;
      }
    }

    setLoading(false); 

    if (createdCount > 0) {
      showSnackbar(`${createdCount} payment document(s) created for new period.`);
    } else {
      showSnackbar("No new payment documents needed.");
    }

    fetchResidentsAndPayments(start, end);
  };

  const fetchResidentsAndPayments = async (start, end) => {
    try {
      setLoading(true);
      const paymentsQuery = query(collection(db, "payments"), where("periodStart", "==", start), where("periodEnd", "==", end));
      const paymentsSnapshot = await getDocs(paymentsQuery);
    
      const residentsData = await Promise.all(
        paymentsSnapshot.docs.map(async (paymentDoc) => {
          const residentRef = doc(db, "residents", paymentDoc.data().residentId);
          const residentSnap = await getDoc(residentRef);
          if (residentSnap.exists()) {
            return {
              id: residentSnap.id,
              ...residentSnap.data(),
              lotNumber: Number(residentSnap.data().lotNumber), 
              paymentId: paymentDoc.id,
              isPaid: paymentDoc.data().isPaid,
              paidDate: paymentDoc.data().paidDate?.toDate()?.toLocaleDateString(),
              onTime: paymentDoc.data().onTime,
              paymentAmount: paymentDoc.data().paymentAmount,
              checkNumber: paymentDoc.data().checkNumber
            };
          } else {
            console.error('Payment document found without a corresponding resident:', paymentDoc.id);
            return null;
          }
        })
      );
    
      // Sort residents by lotNumber in ascending order
      const sortedResidents = residentsData.filter(resident => resident !== null).sort((a, b) => a.lotNumber - b.lotNumber);
    
      setResidents(sortedResidents);
    } catch (error) {
      console.error('Error fetching residents and payments:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentStatusChange = async (resident) => {
    const paymentRef = doc(db, "payments", resident.paymentId || `new-payment-${resident.id}`);

    if (resident.isPaid) {
      const updateData = {
        isPaid: false,
        paidDate: null,
        paymentAmount: '',
        checkNumber: ''
      };
      await setDoc(paymentRef, updateData, { merge: true });
    } else {
      setSelectedResident(resident);
      setPaymentAmount(resident.paymentAmount || '300'); // Default payment amount
      setOpenPaymentDialog(true);
    }

    fetchResidentsAndPayments(period.start, period.end);
  };

  const handlePrevNextPeriod = (direction) => {
    const currentYear = period.start.getFullYear();
    const newYear = direction === 'next' ? (period.start.getMonth() === 0 ? currentYear : currentYear + 1) : (period.start.getMonth() === 0 ? currentYear - 1 : currentYear);
    const periodHalf = period.start.getMonth() === 0 ? 2 : 1;
    initializePeriod(newYear, periodHalf);
  };

  const exportToPDF = (currentPeriod = false) => {
    const doc = new jsPDF({ orientation: 'landscape' });
    const tableColumn = ["Resident Name", "Property Address", "Paid Date", "Payment Amount", "Check Number", "Lot Number"];
    const tableRows = [];
  
    residents.forEach(resident => {
      const residentData = [
        `${resident.firstName} ${resident.lastName}`,
        resident.propertyAddress,
        resident.isPaid ? resident.paidDate : "Not Paid",
        resident.paymentAmount || "N/A",
        resident.checkNumber || "N/A",
        resident.lotNumber
      ];
      tableRows.push(residentData);
    });
  
    doc.text("Tuscany Manor Payment Records", 14, 15);
  
    if (currentPeriod) {
      doc.text(`Payment Period: ${format(period.start, 'MMMM dd, yyyy')} to ${format(period.end, 'MMMM dd, yyyy')}`, 14, 25);
    }
  
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 35,
      theme: 'striped',
      styles: { fontSize: 10, cellPadding: 3 },
      headStyles: { fillColor: [26,29,36] }
    });
  
    const startDateFormatted = format(period.start, 'MM-dd-yyyy');
    const endDateFormatted = format(period.end, 'MM-dd-yyyy');
    const filename = `payment_records_${startDateFormatted}_to_${endDateFormatted}.pdf`;
    doc.save(filename);
  };
  

  const handlePaymentDialogSubmit = async () => {
    console.log("Submitting payment:", { paymentAmount, checkNumber });
    const paymentRef = doc(db, "payments", selectedResident.paymentId || `new-payment-${selectedResident.id}`);
    const updateData = {
      isPaid: true,
      paidDate: serverTimestamp(),
      paymentAmount: paymentAmount,
      checkNumber: checkNumber
    };
    await setDoc(paymentRef, updateData, { merge: true });
    handlePaymentDialogClose();
    fetchResidentsAndPayments(period.start, period.end);

    // Reset the state after submission
    setPaymentAmount('300');
    setCheckNumber('');
  };

  const handleInfoClick = (resident) => {
    console.log("Opening info dialog for resident:", resident);
    setSelectedResident(resident);
    setPaymentAmount(resident.paymentAmount || '300'); // Default payment amount
    setCheckNumber(resident.checkNumber || '');
    setOpenPaymentDialog(true);
  };

  useEffect(() => {
    if (openPaymentDialog && paymentAmountRef.current) {
      paymentAmountRef.current.focus();
      paymentAmountRef.current.setSelectionRange(paymentAmountRef.current.value.length, paymentAmountRef.current.value.length);
    }
  }, [openPaymentDialog]);

  const sortedAndFilteredResidents = residents
    .filter(resident => {
      if (!searchQuery) return true;
      
      const searchLower = searchQuery.toLowerCase();
      return (
        `${resident.firstName} ${resident.lastName}`.toLowerCase().includes(searchLower) ||
        resident.lotNumber.toString().includes(searchLower) ||
        resident.propertyAddress?.toLowerCase().includes(searchLower) ||
        (resident.isPaid ? 'paid' : 'pending').includes(searchLower)
      );
    })
    .sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];
      
      if (sortField === 'lotNumber') {
        return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
      }
      
      if (sortField === 'name') {
        const aName = `${a.firstName} ${a.lastName}`;
        const bName = `${b.firstName} ${b.lastName}`;
        return sortDirection === 'asc' 
          ? aName.localeCompare(bName)
          : bName.localeCompare(aName);
      }
      
      if (sortField === 'status') {
        return sortDirection === 'asc'
          ? (a.isPaid ? 1 : 0) - (b.isPaid ? 1 : 0)
          : (b.isPaid ? 1 : 0) - (a.isPaid ? 1 : 0);
      }
      
      return 0;
    });

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 4 }}>
        <Box 
          sx={{ 
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'flex-start', md: 'center' },
            justifyContent: 'space-between',
            mb: 4,
            gap: 2
          }}
        >
          <Box>
            <Typography 
              variant="h4" 
              component="h1" 
              sx={{ 
                fontWeight: 700,
                color: theme => theme.palette.text.primary,
                mb: 1
              }}
            >
              Payment Management
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ 
                color: theme => theme.palette.text.secondary,
                maxWidth: 600
              }}
            >
              Track and manage HOA dues payments, generate reports, and maintain payment records.
            </Typography>
          </Box>
          
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} width={{ xs: '100%', sm: 'auto' }}>
            <Button
              variant="outlined"
              startIcon={<DownloadIcon />}
              onClick={() => exportToPDF(true)}
              sx={{
                borderRadius: 2,
                textTransform: 'none',
                px: 3,
                py: 1.5,
                borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                color: 'text.primary',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                }
              }}
            >
              Export Period
            </Button>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => createMissingPayments(period.start, period.end)}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              sx={{
                borderRadius: 2,
                textTransform: 'none',
                px: 3,
                py: 1.5,
                bgcolor: '#2d3748',
                color: '#fff',
                boxShadow: 2,
                '&:hover': {
                  bgcolor: '#3a4a61',
                }
              }}
            >
              Create Payments
            </Button>
          </Stack>
        </Box>

        <Card 
          elevation={0} 
          sx={{ 
            mb: 4,
            bgcolor: theme => alpha(theme.palette.primary.main, 0.03),
            border: '1px solid',
            borderColor: theme => alpha(theme.palette.primary.main, 0.08),
            borderRadius: 3,
            overflow: 'hidden'
          }}
        >
          <Box
            sx={{
              p: 3,
              borderBottom: '1px solid',
              borderColor: theme => alpha(theme.palette.primary.main, 0.08),
              bgcolor: theme => alpha(theme.palette.primary.main, 0.05)
            }}
          >
            <Typography 
              variant="h6" 
              sx={{ 
                color: 'text.primary',
                fontWeight: 600,
                mb: 0.5
              }}
            >
              Current Payment Period
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                color: 'text.secondary'
              }}
            >
              Manage payments and track dues for the selected time frame
            </Typography>
          </Box>
          <CardContent sx={{ py: 3 }}>
            <Stack 
              direction={{ xs: 'column', sm: 'row' }} 
              spacing={3} 
              alignItems="center" 
              justifyContent="space-between"
            >
              <Box>
                <Typography 
                  variant="h5" 
                  sx={{ 
                    color: 'text.primary',
                    fontWeight: 600,
                    letterSpacing: '-0.02em'
                  }}
                >
                  {format(period.start, 'MMMM dd, yyyy')} to {format(period.end, 'MMMM dd, yyyy')}
                </Typography>
              </Box>
              <Stack direction="row" spacing={1}>
                <Tooltip title="Previous Period">
                  <Button
                    variant="outlined"
                    onClick={() => handlePrevNextPeriod('prev')}
                    startIcon={<ArrowBackIosIcon />}
                    sx={{
                      borderRadius: 2,
                      textTransform: 'none',
                      borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                      color: 'text.primary',
                      '&:hover': {
                        borderColor: 'primary.main',
                        bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                      }
                    }}
                  >
                    Previous
                  </Button>
                </Tooltip>
                <Tooltip title="Next Period">
                  <Button
                    variant="outlined"
                    onClick={() => handlePrevNextPeriod('next')}
                    endIcon={<ArrowForwardIosIcon />}
                    sx={{
                      borderRadius: 2,
                      textTransform: 'none',
                      borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                      color: 'text.primary',
                      '&:hover': {
                        borderColor: 'primary.main',
                        bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                      }
                    }}
                  >
                    Next
                  </Button>
                </Tooltip>
              </Stack>
            </Stack>
          </CardContent>
        </Card>

        <Card 
          elevation={0} 
          sx={{ 
            mb: 4, 
            bgcolor: theme => alpha(theme.palette.primary.main, 0.03),
            border: '1px solid',
            borderColor: theme => alpha(theme.palette.primary.main, 0.08),
            borderRadius: 3
          }}
        >
          <CardContent sx={{ py: 2 }}>
            <Stack 
              direction={{ xs: 'column', sm: 'row' }} 
              spacing={2} 
              alignItems="center" 
              justifyContent="space-between"
            >
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search residents..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: 'text.secondary' }} />
                    </InputAdornment>
                  ),
                  endAdornment: searchQuery && (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => setSearchQuery('')}
                        sx={{ color: 'text.secondary' }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  maxWidth: 400,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                  }
                }}
              />
            </Stack>
          </CardContent>
        </Card>

        <Alert 
          severity="info" 
          sx={{ 
            mb: 3,
            borderRadius: 2,
            '& .MuiAlert-icon': {
              color: 'primary.main'
            }
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 500, mb: 0.5 }}>
            Tips:
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            • Use the search bar to find specific residents
            <br />
            • Click column headers to sort by that field
            <br />
            • Use Previous/Next buttons to navigate between periods
            <br />
            • Hold the Create Payments button for 2 seconds to clear all payments
          </Typography>
        </Alert>

        <Card elevation={2}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell 
                    sx={{ 
                      fontWeight: 600,
                      cursor: 'pointer',
                      '&:hover': { bgcolor: theme => alpha(theme.palette.primary.main, 0.04) }
                    }}
                    onClick={() => handleSort('name')}
                  >
                    Resident Name {sortField === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}
                  </TableCell>
                  <TableCell 
                    sx={{ 
                      fontWeight: 600,
                      cursor: 'pointer',
                      '&:hover': { bgcolor: theme => alpha(theme.palette.primary.main, 0.04) }
                    }}
                    onClick={() => handleSort('lotNumber')}
                  >
                    Lot Number {sortField === 'lotNumber' && (sortDirection === 'asc' ? '↑' : '↓')}
                  </TableCell>
                  <TableCell 
                    sx={{ 
                      fontWeight: 600,
                      cursor: 'pointer',
                      '&:hover': { bgcolor: theme => alpha(theme.palette.primary.main, 0.04) }
                    }}
                    onClick={() => handleSort('status')}
                  >
                    Status {sortField === 'status' && (sortDirection === 'asc' ? '↑' : '↓')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Paid Date</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  // Loading skeleton animation
                  [...Array(8)].map((_, index) => (
                    <TableRow key={`skeleton-${index}`}>
                      <TableCell>
                        <Skeleton 
                          animation="wave" 
                          height={24} 
                          width="80%" 
                          sx={{ 
                            bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
                            borderRadius: 1
                          }} 
                        />
                      </TableCell>
                      <TableCell>
                        <Skeleton 
                          animation="wave" 
                          height={24} 
                          width={60} 
                          sx={{ 
                            bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
                            borderRadius: 1
                          }} 
                        />
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Skeleton 
                            animation="wave" 
                            variant="circular" 
                            width={20} 
                            height={20}
                            sx={{ 
                              bgcolor: theme => alpha(theme.palette.primary.main, 0.08)
                            }} 
                          />
                          <Skeleton 
                            animation="wave" 
                            height={24} 
                            width={80} 
                            sx={{ 
                              bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
                              borderRadius: 1
                            }} 
                          />
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Skeleton 
                          animation="wave" 
                          height={24} 
                          width={100} 
                          sx={{ 
                            bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
                            borderRadius: 1
                          }} 
                        />
                      </TableCell>
                      <TableCell>
                        <Skeleton 
                          animation="wave" 
                          variant="circular" 
                          width={32} 
                          height={32} 
                          sx={{ 
                            bgcolor: theme => alpha(theme.palette.primary.main, 0.08)
                          }} 
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  sortedAndFilteredResidents.map((resident) => (
                    <TableRow 
                      key={resident.id}
                      sx={{ 
                        '&:hover': { 
                          bgcolor: theme => alpha(theme.palette.primary.main, 0.04)
                        }
                      }}
                    >
                      <TableCell>{resident.firstName} {resident.lastName}</TableCell>
                      <TableCell>{resident.lotNumber}</TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            gap: 1
                          }}
                        >
                          <Tooltip title={resident.isPaid ? "Mark as Unpaid" : "Mark as Paid"}>
                            <Checkbox
                              checked={resident.isPaid}
                              onChange={() => handlePaymentStatusChange(resident)}
                              sx={{
                                color: theme => alpha(theme.palette.primary.main, 0.3),
                                '&.Mui-checked': {
                                  color: 'success.main',
                                },
                                '&:hover': {
                                  bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                                }
                              }}
                            />
                          </Tooltip>
                          <Box
                            sx={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              px: 1,
                              py: 0.5,
                              borderRadius: 1,
                              bgcolor: resident.isPaid ? 'success.soft' : 'warning.soft',
                              color: resident.isPaid ? 'success.main' : 'warning.main',
                            }}
                          >
                            <Typography variant="body2">
                              {resident.isPaid ? 'Paid' : 'Pending'}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>{resident.paidDate || 'Pending'}</TableCell>
                      <TableCell>
                        <Tooltip title={resident.isPaid ? "View/Edit Payment" : "Record Payment"}>
                          <IconButton
                            size="small"
                            onClick={() => handleInfoClick(resident)}
                            sx={{ 
                              color: 'primary.main',
                              '&:hover': {
                                bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                              }
                            }}
                          >
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        )}
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2
          }
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
            Confirm Reset
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ pt: 2 }}>
          <Stack spacing={3}>
            <Typography variant="body1" color="text.secondary">
              Are you sure you want to clear all payments for this period? This action cannot be undone.
            </Typography>
            <Box>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                Type <strong>CLEAR</strong> to confirm:
              </Typography>
              <TextField
                fullWidth
                value={clearConfirmation}
                onChange={handleClearConfirmationChange}
                error={clearError}
                helperText={clearError ? "Please type CLEAR to confirm" : ""}
                autoFocus
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                  }
                }}
              />
            </Box>
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2" color="error" sx={{ fontWeight: 500 }}>
                Warning: This will permanently delete all payment records for:
              </Typography>
              <Typography variant="body2" color="error">
                {format(period.start, 'MMMM dd, yyyy')} to {format(period.end, 'MMMM dd, yyyy')}
              </Typography>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button 
            onClick={handleDialogClose}
            variant="outlined"
            sx={{ 
              textTransform: 'none',
              minWidth: 100,
              borderRadius: 2,
              borderColor: theme => alpha(theme.palette.primary.main, 0.2),
              color: 'text.primary',
              '&:hover': {
                borderColor: 'primary.main',
                bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={clearPaymentsForPeriod}
            variant="contained"
            color="error"
            disabled={clearConfirmation !== 'CLEAR' || loading}
            sx={{ 
              textTransform: 'none',
              minWidth: 100,
              borderRadius: 2,
              boxShadow: 2,
              '&:hover': {
                bgcolor: 'error.dark'
              }
            }}
          >
            {loading ? 'Clearing...' : 'Clear All'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={openPaymentDialog} 
        onClose={handlePaymentDialogClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2
          }
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
            Payment Information
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ pt: 2 }}>
          <Stack spacing={3}>
            {selectedResident && (
              <Typography variant="body1" color="text.secondary">
                Recording payment for: {selectedResident.firstName} {selectedResident.lastName}
              </Typography>
            )}
            <TextField
              label="Payment Amount"
              type="number"
              fullWidth
              value={paymentAmount}
              onChange={(e) => setPaymentAmount(e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              inputRef={paymentAmountRef}
            />
            <TextField
              label="Check Number"
              type="text"
              fullWidth
              value={checkNumber}
              onChange={(e) => setCheckNumber(e.target.value)}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button 
            onClick={handlePaymentDialogClose}
            variant="outlined"
            sx={{ 
              textTransform: 'none',
              minWidth: 100,
              borderRadius: 2,
              borderColor: theme => alpha(theme.palette.primary.main, 0.2),
              color: 'text.primary',
              '&:hover': {
                borderColor: 'primary.main',
                bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handlePaymentDialogSubmit}
            variant="contained"
            sx={{ 
              textTransform: 'none',
              minWidth: 100,
              borderRadius: 2,
              boxShadow: 2
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={openSnackbar} 
        autoHideDuration={6000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity="info" 
          sx={{ 
            width: '100%',
            borderRadius: 2
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Payments;
