import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography, Container, Box, IconButton, Grid, Fab, Snackbar, Alert } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DescriptionIcon from '@mui/icons-material/Description';
import FeedbackIcon from '@mui/icons-material/Feedback';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';
import emailjs from 'emailjs-com';
import PeopleIcon from '@mui/icons-material/People';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { alpha } from '@mui/material/styles';

const MotionBox = motion(Box);

function Home() {
    const navigate = useNavigate();
    const [openAbout, setOpenAbout] = useState(false);
    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    const fadeIn = {
        hidden: { opacity: 0 },
        visible: { 
            opacity: 1,
            transition: { duration: 0.3 }
        }
    };

    const handleOpenAbout = () => setOpenAbout(true);
    const handleCloseAbout = () => setOpenAbout(false);
    const handleOpenFeedback = () => setOpenFeedback(true);
    const handleCloseFeedback = () => {
        setOpenFeedback(false);
        setFeedback('');
    };

    const handleSubmitFeedback = () => {
        if (!feedback.trim()) {
            setSnackbar({
                open: true,
                message: 'Please enter your feedback before submitting',
                severity: 'warning'
            });
            return;
        }

        const templateParams = {
            feedback: feedback 
        };

        emailjs.send('service_oy4wfrw', 'template_e29jwot', templateParams, '3RFTYojQthQm2u5yB')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setSnackbar({
                    open: true,
                    message: 'Thank you for your feedback!',
                    severity: 'success'
                });
                setFeedback('');
                handleCloseFeedback();
            }, (error) => {
                console.log('FAILED...', error);
                setSnackbar({
                    open: true,
                    message: 'Failed to send feedback. Please try again.',
                    severity: 'error'
                });
            });
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbar({ ...snackbar, open: false });
    };

    const openTermsOfService = () => {
        window.open('/terms.pdf', '_blank');
    };

    const quickActions = [
        {
            title: 'Home',
            description: 'Return to dashboard',
            icon: <HomeIcon sx={{ fontSize: 24 }} />,
            path: '/',
            color: '#4F46E5'
        },
        {
            title: 'Residents',
            description: 'Manage resident information',
            icon: <PeopleIcon sx={{ fontSize: 24 }} />,
            path: '/residents',
            color: '#059669'
        },
        {
            title: 'HOA Invoices',
            description: 'Generate HOA invoices',
            icon: <ReceiptIcon sx={{ fontSize: 24 }} />,
            path: '/invoices',
            color: '#B45309'
        },
        {
            title: 'HOA Dues',
            description: 'Track HOA dues',
            icon: <PaymentIcon sx={{ fontSize: 24 }} />,
            path: '/payments',
            color: '#7C3AED'
        },
        {
            title: 'Bills Payable',
            description: 'Manage neighborhood bills',
            icon: <PaymentIcon sx={{ fontSize: 24 }} />,
            path: '/bills-payable',
            color: '#DC2626'
        },
        {
            title: 'Ledger',
            description: 'View financial records',
            icon: <AccountBalanceIcon sx={{ fontSize: 24 }} />,
            path: '/ledger',
            color: '#3B82F6'
        }
    ];

    return (
        <Box 
            sx={{ 
                minHeight: 'calc(100vh - 64px)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                py: 6,
                px: 3,
                backgroundColor: '#18181B'
            }}
        >
            <Container maxWidth="lg">
                <MotionBox
                    initial="hidden"
                    animate="visible"
                    variants={fadeIn}
                >
                    <Box sx={{ mb: 6, textAlign: 'center' }}>
                        <Typography 
                            variant="h3" 
                            sx={{ 
                                fontWeight: 700,
                                letterSpacing: '-0.02em',
                                mb: 2,
                                color: '#FFFFFF'
                            }}
                        >
                            myHOA Portal
                        </Typography>
                        <Typography 
                            variant="body1" 
                            sx={{ 
                                color: '#71717A',
                                maxWidth: '600px',
                                mx: 'auto',
                                mb: 2
                            }}
                        >
                            Manage your HOA community with our comprehensive suite of tools
                        </Typography>
                        <Box
                            sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                px: 2,
                                py: 0.75,
                                borderRadius: 2,
                                bgcolor: theme => alpha(theme.palette.primary.main, 0.1),
                                border: '1px solid',
                                borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                                mb: 4
                            }}
                        >
                            <Typography 
                                variant="body2" 
                                sx={{ 
                                    color: '#FFFFFF',
                                    fontWeight: 500,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1
                                }}
                            >
                                <Box
                                    component="span"
                                    sx={{
                                        width: 6,
                                        height: 6,
                                        borderRadius: '50%',
                                        bgcolor: '#4F46E5'
                                    }}
                                />
                                Licensed and custom built for Tuscany Manor
                            </Typography>
                        </Box>
                    </Box>

                    <Box 
                        sx={{ 
                            position: 'fixed',
                            top: 24,
                            right: 24,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            gap: 1,
                            zIndex: 1000
                        }}
                    >
                        <Typography 
                            variant="body2" 
                            sx={{ 
                                color: '#71717A',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5
                            }}
                        >
                            <Box
                                component="a"
                                href="https://garrettflowers.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    color: '#71717A',
                                    textDecoration: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 0.5,
                                    '&:hover': {
                                        color: '#A1A1AA'
                                    }
                                }}
                            >
                                <Box
                                    component="span"
                                    sx={{
                                        width: 4,
                                        height: 4,
                                        borderRadius: '50%',
                                        bgcolor: '#71717A'
                                    }}
                                />
                                Built by Garrett Flowers
                            </Box>
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center'
                            }}
                        >
                            <IconButton
                                onClick={handleOpenAbout}
                                size="small"
                                sx={{
                                    color: '#71717A',
                                    '&:hover': {
                                        color: '#A1A1AA',
                                        bgcolor: theme => alpha(theme.palette.primary.main, 0.04)
                                    }
                                }}
                            >
                                <InfoIcon sx={{ fontSize: 18 }} />
                            </IconButton>
                            <IconButton
                                onClick={openTermsOfService}
                                size="small"
                                sx={{
                                    color: '#71717A',
                                    '&:hover': {
                                        color: '#A1A1AA',
                                        bgcolor: theme => alpha(theme.palette.primary.main, 0.04)
                                    }
                                }}
                            >
                                <DescriptionIcon sx={{ fontSize: 18 }} />
                            </IconButton>
                        </Box>
                    </Box>

                    <Grid container spacing={2} sx={{ mb: 4 }}>
                        {quickActions.map((action, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Box
                                    onClick={() => navigate(action.path)}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        p: 2.5,
                                        borderRadius: 2,
                                        cursor: 'pointer',
                                        transition: 'all 0.2s ease-in-out',
                                        bgcolor: theme => alpha(theme.palette.primary.main, 0.03),
                                        border: '1px solid',
                                        borderColor: theme => alpha(theme.palette.primary.main, 0.08),
                                        '&:hover': {
                                            bgcolor: theme => alpha(theme.palette.primary.main, 0.06),
                                            borderColor: action.color,
                                            transform: 'translateX(4px)',
                                        }
                                    }}
                                >
                                    <Box 
                                        sx={{ 
                                            width: 36,
                                            height: 36,
                                            borderRadius: 1,
                                            bgcolor: `${action.color}15`,
                                            color: action.color,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            mr: 2,
                                            transition: 'all 0.2s ease-in-out',
                                            '&:hover': {
                                                bgcolor: `${action.color}25`,
                                            }
                                        }}
                                    >
                                        {action.icon}
                                    </Box>
                                    <Box sx={{ flex: 1 }}>
                                        <Typography 
                                            variant="subtitle1" 
                                            sx={{ 
                                                fontWeight: 600,
                                                color: '#FFFFFF',
                                                mb: 0.5
                                            }}
                                        >
                                            {action.title}
                                        </Typography>
                                        <Typography 
                                            variant="body2" 
                                            sx={{ 
                                                color: '#A1A1AA',
                                                fontSize: '0.875rem'
                                            }}
                                        >
                                            {action.description}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </MotionBox>
            </Container>

            <Fab
                size="medium"
                aria-label="feedback"
                onClick={handleOpenFeedback}
                sx={{
                    position: 'fixed',
                    bottom: 32,
                    right: 32,
                    backgroundColor: '#3F3F46',
                    color: '#FFFFFF',
                    border: '1px solid #52525B',
                    '&:hover': {
                        backgroundColor: '#52525B',
                    },
                }}
            >
                <FeedbackIcon />
            </Fab>

            <Dialog
                open={openAbout}
                onClose={handleCloseAbout}
                maxWidth="md"
                TransitionComponent={motion.div}
                PaperProps={{
                    sx: {
                        backgroundColor: '#27272A',
                        borderRadius: 3,
                        border: '1px solid #3F3F46',
                        overflow: 'hidden',
                        margin: 2,
                        width: '100%',
                        maxWidth: '800px',
                    },
                }}
            >
                <DialogTitle 
                    sx={{ 
                        borderBottom: '1px solid #3F3F46',
                        p: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        gap: 1,
                    }}
                >
                    <Box 
                        sx={{ 
                            width: 48,
                            height: 48,
                            borderRadius: 2,
                            backgroundColor: '#3F3F46',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mb: 1,
                        }}
                    >
                        <InfoIcon sx={{ fontSize: 24, color: '#FFFFFF' }} />
                    </Box>
                    <Typography variant="h5" sx={{ fontWeight: 600 }}>
                        About the Portal
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#A1A1AA', maxWidth: '600px' }}>
                        Everything you need to know about managing your HOA community
                    </Typography>
                    <IconButton
                        onClick={handleCloseAbout}
                        sx={{ 
                            position: 'absolute',
                            right: 16,
                            top: 16,
                            color: '#71717A',
                            '&:hover': { 
                                backgroundColor: '#3F3F46',
                                color: '#FFFFFF'
                            }
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 4, pt: 4 }}>
                    <Grid container spacing={4}>
                        {[
                            {
                                title: 'Residents',
                                description: 'Create, edit, and delete resident profiles. Manage all resident account information from a central dashboard.',
                                icon: <PeopleIcon sx={{ fontSize: 24 }} />
                            },
                            {
                                title: 'HOA Invoices',
                                description: 'Generate and manage PDF invoices using resident profile information stored in the database.',
                                icon: <ReceiptIcon sx={{ fontSize: 24 }} />
                            },
                            {
                                title: 'HOA Dues',
                                description: 'Track and manage HOA Dues payments records. All records are sorted by period and stored securely.',
                                icon: <PaymentIcon sx={{ fontSize: 24 }} />
                            },
                            {
                                title: 'Bills Payable',
                                description: 'Manage all neighborhood billing records with easy sorting and filtering options.',
                                icon: <AccountBalanceIcon sx={{ fontSize: 24 }} />
                            },
                            {
                                title: 'Ledger',
                                description: 'View your neighborhood balance and financial overview with detailed transaction history.',
                                icon: <AccountBalanceIcon sx={{ fontSize: 24 }} />
                            }
                        ].map((item, index) => (
                            <Grid item xs={12} md={6} key={index}>
                                <Box sx={{ 
                                    p: 3,
                                    backgroundColor: '#18181B',
                                    borderRadius: 2,
                                    border: '1px solid #3F3F46',
                                    height: '100%'
                                }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Box sx={{
                                            width: 40,
                                            height: 40,
                                            borderRadius: 1.5,
                                            backgroundColor: '#3F3F46',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            mr: 2
                                        }}>
                                            {item.icon}
                                        </Box>
                                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                            {item.title}
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" sx={{ color: '#A1A1AA' }}>
                                        {item.description}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions 
                    sx={{ 
                        p: 3,
                        borderTop: '1px solid #3F3F46',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Button 
                        onClick={handleCloseAbout}
                        variant="contained"
                        sx={{
                            backgroundColor: '#3F3F46',
                            color: '#FFFFFF',
                            px: 4,
                            '&:hover': {
                                backgroundColor: '#52525B',
                            },
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openFeedback}
                onClose={handleCloseFeedback}
                maxWidth="sm"
                fullWidth
                TransitionComponent={motion.div}
                PaperProps={{
                    sx: {
                        backgroundColor: '#27272A',
                        borderRadius: 3,
                        border: '1px solid #3F3F46',
                        overflow: 'hidden',
                        margin: 2,
                        width: '100%',
                        maxWidth: '500px',
                    },
                }}
            >
                <DialogTitle 
                    sx={{ 
                        borderBottom: '1px solid #3F3F46',
                        p: 3,
                        pb: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        gap: 1,
                    }}
                >
                    <Box 
                        sx={{ 
                            width: 48,
                            height: 48,
                            borderRadius: 2,
                            backgroundColor: '#3F3F46',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mb: 1,
                        }}
                    >
                        <FeedbackIcon sx={{ fontSize: 24, color: '#FFFFFF' }} />
                    </Box>
                    <Typography variant="h5" sx={{ fontWeight: 600 }}>
                        Share Your Feedback
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#A1A1AA', maxWidth: '400px' }}>
                        Help us improve your experience by sharing your thoughts, suggestions, or reporting any issues.
                    </Typography>
                    <IconButton
                        onClick={handleCloseFeedback}
                        sx={{ 
                            position: 'absolute',
                            right: 16,
                            top: 16,
                            color: '#71717A',
                            '&:hover': { 
                                backgroundColor: '#3F3F46',
                                color: '#FFFFFF'
                            }
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent 
                    sx={{ 
                        p: 3,
                        pt: 4,
                        mt: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <TextField
                        autoFocus
                        multiline
                        rows={5}
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        placeholder="What's on your mind?"
                        fullWidth
                        variant="outlined"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: '#18181B',
                                borderRadius: 2,
                                transition: 'all 0.2s ease-in-out',
                                '&:hover': {
                                    backgroundColor: '#1F1F23',
                                    '& fieldset': {
                                        borderColor: '#52525B',
                                    },
                                },
                                '& fieldset': {
                                    borderColor: '#3F3F46',
                                    transition: 'border-color 0.2s ease-in-out',
                                },
                                '&.Mui-focused': {
                                    '& fieldset': {
                                        borderColor: '#71717A',
                                        borderWidth: '1px',
                                    },
                                },
                            },
                            '& .MuiInputBase-input': {
                                color: '#FFFFFF',
                                '&::placeholder': {
                                    color: '#71717A',
                                    opacity: 1,
                                },
                            },
                        }}
                    />
                    <Typography variant="caption" sx={{ color: '#71717A', textAlign: 'right' }}>
                        {feedback.length} / 1000 characters
                    </Typography>
                </DialogContent>
                <DialogActions 
                    sx={{ 
                        p: 3,
                        borderTop: '1px solid #3F3F46',
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2,
                    }}
                >
                    <Button 
                        onClick={handleCloseFeedback}
                        variant="outlined"
                        sx={{
                            borderColor: '#3F3F46',
                            color: '#FFFFFF',
                            px: 4,
                            '&:hover': {
                                borderColor: '#52525B',
                                backgroundColor: '#3F3F46',
                            },
                        }}
                    >
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleSubmitFeedback}
                        variant="contained"
                        sx={{
                            backgroundColor: '#3F3F46',
                            color: '#FFFFFF',
                            px: 4,
                            '&:hover': {
                                backgroundColor: '#52525B',
                            },
                        }}
                    >
                        Submit Feedback
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleCloseSnackbar} 
                    severity={snackbar.severity}
                    sx={{ 
                        backgroundColor: '#27272A',
                        color: '#FFFFFF',
                        border: '1px solid #3F3F46',
                        '& .MuiAlert-icon': {
                            color: snackbar.severity === 'success' ? '#22C55E' : 
                                   snackbar.severity === 'error' ? '#EF4444' : '#F59E0B'
                        }
                    }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default Home;
