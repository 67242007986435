import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider, CssBaseline, Box, CircularProgress } from '@mui/material';
import { isMobile } from './deviceUtils';
import Residents from './components/Residents';
import Home from './components/Home';
import Payments from './components/Payments';
import MobilePayments from './components/MobilePayments';
import Invoices from './components/Invoices';
import Login from './components/Login';
import Sidebar from './components/Sidebar';
import { AuthProvider, useAuth } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';
import Admin from './components/Admin';
import Terms from './components/Terms';
import Profiles from './components/Profiles';
import BillsPayable from './components/BillsPayable';
import Ledger from './components/Ledger';
import MobileLedger from './components/MobileLedger';

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
    h1: {
      fontWeight: 600,
      letterSpacing: '-0.02em',
    },
    h2: {
      fontWeight: 600,
      letterSpacing: '-0.02em',
    },
    h3: {
      fontWeight: 600,
      letterSpacing: '-0.02em',
    },
    h4: {
      fontWeight: 600,
      letterSpacing: '-0.02em',
    },
    h5: {
      fontWeight: 500,
      letterSpacing: '-0.02em',
    },
    h6: {
      fontWeight: 500,
      letterSpacing: '-0.02em',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.5,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#A1A1AA',
    },
    background: {
      default: '#18181B',
      paper: '#27272A',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#A1A1AA',
    },
  },
  shape: {
    borderRadius: 12,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 12,
          padding: '10px 20px',
          backgroundColor: '#27272A',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#3F3F46',
          },
          '&.MuiButton-contained': {
            color: '#FFFFFF',
            '&.Mui-disabled': {
              color: 'rgba(255, 255, 255, 0.5)',
              backgroundColor: '#3F3F46',
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          backgroundColor: '#27272A',
          borderRadius: 16,
          border: '1px solid #3F3F46',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          border: 'none',
          backgroundColor: '#18181B',
          borderRight: '1px solid #27272A',
          width: 280,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            backgroundColor: '#18181B',
            borderRadius: 12,
            '& fieldset': {
              borderColor: '#3F3F46',
            },
            '&:hover fieldset': {
              borderColor: '#52525B',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#71717A',
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#27272A',
          color: '#FFFFFF',
          border: '1px solid #3F3F46',
          borderRadius: 8,
          fontSize: '0.875rem',
          padding: '8px 12px',
        },
        arrow: {
          color: '#27272A',
        },
        popper: {
          transition: 'none !important',
        },
      },
      defaultProps: {
        arrow: true,
        placement: 'top',
        enterDelay: 100,
        leaveDelay: 0,
        TransitionProps: { timeout: 150 },
      },
    },
  },
});

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Layout />
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

const Layout = () => {
  const { user, isAdmin, loading } = useAuth();
  const navigate = useNavigate();
  const [typedKeys, setTypedKeys] = useState('');

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && typedKeys === 'admin' && isAdmin) {
        navigate('/admin');
      } else {
        setTypedKeys((prev) => (prev + event.key).slice(-5));
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [typedKeys, isAdmin, navigate]);

  if (loading) {
    return (
      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1, 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh',
          backgroundColor: '#18181B',
        }}
      >
        <CircularProgress sx={{ color: '#FFFFFF' }} />
      </Box>
    );
  }

  if (!user) {
    return (
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', backgroundColor: '#18181B' }}>
      {isMobile() ? (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Routes>
            <Route path="/profiles" element={<Profiles />} />
            <Route path="/mobileledger" element={<MobileLedger />} />
            <Route path="/mobilepayments" element={<MobilePayments />} />
            <Route path="*" element={<Navigate to="/profiles" />} />
          </Routes>
        </Box>
      ) : (
        <>
          <Sidebar />
          <Box 
            component="main" 
            sx={{ 
              flexGrow: 1, 
              p: 3,
              width: `calc(100% - 280px)`,
              transition: 'width 0.2s ease-out',
            }}
          >
            <Routes>
              <Route path="/login" element={user ? <Navigate replace to="/" /> : <Login />} />
              <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
              <Route path="/residents" element={<ProtectedRoute><Residents /></ProtectedRoute>} />
              <Route path="/payments" element={<ProtectedRoute><Payments /></ProtectedRoute>} />
              <Route path="/invoices" element={<ProtectedRoute><Invoices /></ProtectedRoute>} />
              <Route path="/bills-payable" element={<ProtectedRoute><BillsPayable /></ProtectedRoute>} />
              <Route path="/ledger" element={<ProtectedRoute><Ledger /></ProtectedRoute>} />
              <Route path="/admin" element={isAdmin ? <Admin /> : <Navigate to="/" />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Box>
        </>
      )}
    </Box>
  );
};

export default App;
