import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import { collection, addDoc, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Table, TableBody, TableCell, TableHead, TableRow, Box, Container,
  IconButton, MenuItem, Select, Typography, FormControl, InputLabel, FormControlLabel, Checkbox, Menu, List, ListItem,
  Card, CardContent, Divider, Stack, Tooltip, TableContainer, alpha, Skeleton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SortIcon from '@mui/icons-material/Sort';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const Residents = () => {
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [residents, setResidents] = useState([]);
  const [currentResident, setCurrentResident] = useState({
    firstName: '',
    lastName: '',
    spouseName: '',
    propertyAddress: '',
    mailingAddress: '',
    lotNumber: '',
    email: '',
    phoneNumber: '',
    accountNumber: '',
  });
  const [isMailingSameAsProperty, setIsMailingSameAsProperty] = useState(true);
  const [displayAddressType, setDisplayAddressType] = useState('propertyAddress');
  const [sortCriteria, setSortCriteria] = useState('lotNumber');
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState({
    firstName: true,
    lastName: true,
    spouseName: false,
    propertyAddress: true,
    mailingAddress: true,
    lotNumber: true,
    email: false,
    phoneNumber: true,
    accountNumber: true,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchResidents();
  }, []);

  const fetchResidents = async () => {
    try {
      setLoading(true);
      const residentsCollection = collection(db, "residents");
      const residentsSnapshot = await getDocs(residentsCollection);
      const residentsList = residentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setResidents(residentsList);
    } catch (error) {
      console.error("Error fetching residents:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = (resident = null) => {
    if (resident) {
      setCurrentResident(resident);
      setEditing(true);
      setIsMailingSameAsProperty(resident.propertyAddress === resident.mailingAddress);
    } else {
      setCurrentResident({
        firstName: '',
        lastName: '',
        spouseName: '',
        propertyAddress: '',
        mailingAddress: '',
        lotNumber: '',
        email: '',
        phoneNumber: '',
        accountNumber: '',
      });
      setEditing(false);
      setIsMailingSameAsProperty(true);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phoneNumber') {
      const formattedPhoneNumber = formatPhoneNumber(value);
      setCurrentResident(prev => ({
        ...prev,
        [name]: formattedPhoneNumber
      }));
    } else {
      setCurrentResident(prev => ({
        ...prev,
        [name]: value
      }));
    }
    if (name === 'propertyAddress' && isMailingSameAsProperty) {
      setCurrentResident(prev => ({
        ...prev,
        mailingAddress: value
      }));
    }
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsMailingSameAsProperty(checked);
    if (checked) {
      setCurrentResident(prev => ({
        ...prev,
        mailingAddress: prev.propertyAddress
      }));
    }
  };

  const handleSubmit = async () => {
    if (!currentResident.firstName) {
      alert('Please fill in the first name.');
      return;
    }
    try {
      if (editing) {
        const docRef = doc(db, "residents", currentResident.id);
        await updateDoc(docRef, currentResident);
      } else {
        await addDoc(collection(db, "residents"), currentResident);
      }
      fetchResidents();
      handleClose();
    } catch (error) {
      console.error("Error adding/updating resident: ", error);
      alert("Error adding/updating resident: " + error.message);
    }
  };

  const handleDeleteResident = async () => {
    if (currentResident.id) {
      try {
        await deleteDoc(doc(db, "residents", currentResident.id));
        fetchResidents();
        handleClose();
        console.log("Resident deleted successfully");
      } catch (error) {
        console.error("Error deleting resident: ", error);
        alert("Error deleting resident: " + error.message);
      }
    }
  };

  const handleSortChange = (event) => {
    setSortCriteria(event.target.value);
  };

  const sortResidents = (residents, criteria) => {
    return [...residents].sort((a, b) => {
      if (criteria === 'lotNumber') {
        return parseInt(a[criteria]) - parseInt(b[criteria]);
      }
      if (a[criteria] < b[criteria]) return -1;
      if (a[criteria] > b[criteria]) return 1;
      return 0;
    });
  };

  const sortedResidents = sortResidents(residents, sortCriteria);

  const toggleDisplayAddressType = () => {
    setDisplayAddressType(prev => (prev === 'propertyAddress' ? 'mailingAddress' : 'propertyAddress'));
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (event) => {
    setFilters(prev => ({
      ...prev,
      [event.target.name]: event.target.checked
    }));
  };

  const exportToPDF = () => {
    const doc = new jsPDF({ orientation: 'landscape' });
    const tableColumn = [
      "Lot Number",
      "First Name",
      "Last Name",
      "Spouse Name",
      "Property Address",
      "Mailing Address",
      "Email",
      "Phone Number",
      "Account Number"
    ];
    const tableRows = [];
  
    sortedResidents.forEach(resident => {
      const residentData = [
        resident.lotNumber,
        resident.firstName,
        resident.lastName,
        resident.spouseName,
        resident.propertyAddress,
        resident.mailingAddress,
        resident.email,
        formatPhoneNumber(resident.phoneNumber),
        resident.accountNumber
      ];
      tableRows.push(residentData);
    });
  
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    const filenameDate = formattedDate.replace(/\//g, '');
  
    doc.text(`Tuscany Residents - ${formattedDate}`, 14, 15);
  
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 25,
      theme: 'striped',
      styles: { fontSize: 10, cellPadding: 3 },
      headStyles: { fillColor: [26, 29, 36] }
    });
  
    doc.save(`Tuscany_Residents_${filenameDate}.pdf`);
  };
  
  
  
  const isMenuOpen = Boolean(anchorEl);

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 4 }}>
        <Box 
          sx={{ 
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'flex-start', md: 'center' },
            justifyContent: 'space-between',
            mb: 4,
            gap: 2
          }}
        >
          <Box>
            <Typography 
              variant="h4" 
              component="h1" 
              sx={{ 
                fontWeight: 700,
                color: theme => theme.palette.text.primary,
                mb: 1
              }}
            >
              Resident Management
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ 
                color: theme => theme.palette.text.secondary,
                maxWidth: 600
              }}
            >
              Manage and organize resident information, generate reports, and keep track of property details.
            </Typography>
          </Box>
          
          <Button
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => handleOpen()}
            sx={{
              borderRadius: 2,
              textTransform: 'none',
              px: 3,
              py: 1.5,
              boxShadow: 2,
              bgcolor: '#2d3748',
              color: '#fff',
              '&:hover': {
                bgcolor: '#3a4a61'
              }
            }}
          >
            Add New Resident
          </Button>
        </Box>

        <Card 
          elevation={0} 
          sx={{ 
            mb: 4, 
            bgcolor: theme => alpha(theme.palette.primary.main, 0.03),
            border: '1px solid',
            borderColor: theme => alpha(theme.palette.primary.main, 0.08),
            borderRadius: 3
          }}
        >
          <CardContent sx={{ py: 2 }}>
            <Stack 
              direction={{ xs: 'column', sm: 'row' }} 
              spacing={{ xs: 2, sm: 3 }} 
              alignItems="center" 
              justifyContent="space-between"
            >
              <Stack 
                direction={{ xs: 'column', sm: 'row' }} 
                spacing={2} 
                alignItems={{ xs: 'stretch', sm: 'center' }}
                width={{ xs: '100%', sm: 'auto' }}
              >
                <FormControl 
                  size="small" 
                  sx={{ 
                    minWidth: { xs: '100%', sm: 200 },
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 2,
                    }
                  }}
                >
                  <InputLabel>Sort Residents</InputLabel>
                  <Select
                    value={sortCriteria}
                    onChange={handleSortChange}
                    label="Sort Residents"
                    startAdornment={<SortIcon sx={{ ml: 1, mr: 1, opacity: 0.5 }} />}
                  >
                    <MenuItem value="firstName">First Name</MenuItem>
                    <MenuItem value="lastName">Last Name</MenuItem>
                    <MenuItem value="lotNumber">Lot Number</MenuItem>
                  </Select>
                </FormControl>

                <Tooltip title="Filter columns" placement="bottom">
                  <Button
                    variant="outlined"
                    startIcon={<FilterListIcon />}
                    onClick={handleFilterClick}
                    sx={{
                      borderRadius: 2,
                      textTransform: 'none',
                      borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                      bgcolor: 'background.paper',
                      minWidth: { xs: '100%', sm: 130 },
                      '&:hover': {
                        borderColor: 'primary.main',
                        bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                      }
                    }}
                  >
                    Filters
                  </Button>
                </Tooltip>
              </Stack>

              <Button
                variant="outlined"
                startIcon={<PictureAsPdfIcon />}
                onClick={exportToPDF}
                sx={{
                  borderRadius: 2,
                  textTransform: 'none',
                  borderColor: theme => alpha(theme.palette.primary.main, 0.2),
                  bgcolor: 'background.paper',
                  minWidth: { xs: '100%', sm: 'auto' },
                  px: 3,
                  '&:hover': {
                    borderColor: 'primary.main',
                    bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
                  }
                }}
              >
                Export PDF
              </Button>
            </Stack>
          </CardContent>
        </Card>

        <Card elevation={2}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  {filters.firstName && <TableCell sx={{ fontWeight: 600 }}>First</TableCell>}
                  {filters.lastName && <TableCell sx={{ fontWeight: 600 }}>Last</TableCell>}
                  {filters.spouseName && <TableCell sx={{ fontWeight: 600 }}>Spouse</TableCell>}
                  {filters[displayAddressType] && (
                    <TableCell 
                      onClick={toggleDisplayAddressType} 
                      sx={{ 
                        fontWeight: 600, 
                        cursor: 'pointer',
                        '&:hover': {
                          color: 'primary.main'
                        }
                      }}
                    >
                      {displayAddressType === 'propertyAddress' ? '▼ Property Address' : '▲ Mailing Address'}
                    </TableCell>
                  )}
                  {filters.lotNumber && <TableCell sx={{ fontWeight: 600 }}>Lot</TableCell>}
                  {filters.email && <TableCell sx={{ fontWeight: 600 }}>Email</TableCell>}
                  {filters.phoneNumber && <TableCell sx={{ fontWeight: 600 }}>Phone</TableCell>}
                  {filters.accountNumber && <TableCell sx={{ fontWeight: 600 }}>Account</TableCell>}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  // Loading skeleton animation
                  [...Array(8)].map((_, index) => (
                    <TableRow key={`skeleton-${index}`}>
                      {filters.firstName && (
                        <TableCell>
                          <Skeleton 
                            animation="wave" 
                            height={24} 
                            width="80%" 
                            sx={{ 
                              bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
                              borderRadius: 1
                            }} 
                          />
                        </TableCell>
                      )}
                      {filters.lastName && (
                        <TableCell>
                          <Skeleton 
                            animation="wave" 
                            height={24} 
                            width="80%" 
                            sx={{ 
                              bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
                              borderRadius: 1
                            }} 
                          />
                        </TableCell>
                      )}
                      {filters.spouseName && (
                        <TableCell>
                          <Skeleton 
                            animation="wave" 
                            height={24} 
                            width="60%" 
                            sx={{ 
                              bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
                              borderRadius: 1
                            }} 
                          />
                        </TableCell>
                      )}
                      {filters[displayAddressType] && (
                        <TableCell>
                          <Skeleton 
                            animation="wave" 
                            height={24} 
                            width="90%" 
                            sx={{ 
                              bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
                              borderRadius: 1
                            }} 
                          />
                        </TableCell>
                      )}
                      {filters.lotNumber && (
                        <TableCell>
                          <Skeleton 
                            animation="wave" 
                            height={24} 
                            width={40} 
                            sx={{ 
                              bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
                              borderRadius: 1
                            }} 
                          />
                        </TableCell>
                      )}
                      {filters.email && (
                        <TableCell>
                          <Skeleton 
                            animation="wave" 
                            height={24} 
                            width="85%" 
                            sx={{ 
                              bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
                              borderRadius: 1
                            }} 
                          />
                        </TableCell>
                      )}
                      {filters.phoneNumber && (
                        <TableCell>
                          <Skeleton 
                            animation="wave" 
                            height={24} 
                            width={120} 
                            sx={{ 
                              bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
                              borderRadius: 1
                            }} 
                          />
                        </TableCell>
                      )}
                      {filters.accountNumber && (
                        <TableCell>
                          <Skeleton 
                            animation="wave" 
                            height={24} 
                            width={100} 
                            sx={{ 
                              bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
                              borderRadius: 1
                            }} 
                          />
                        </TableCell>
                      )}
                      <TableCell>
                        <Skeleton 
                          animation="wave" 
                          variant="circular" 
                          width={32} 
                          height={32} 
                          sx={{ 
                            bgcolor: theme => alpha(theme.palette.primary.main, 0.08)
                          }} 
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  sortedResidents.map(resident => (
                    <TableRow 
                      key={resident.id}
                      sx={{ 
                        '&:hover': { 
                          bgcolor: theme => alpha(theme.palette.primary.main, 0.04)
                        }
                      }}
                    >
                      {filters.firstName && <TableCell>{resident.firstName}</TableCell>}
                      {filters.lastName && <TableCell>{resident.lastName}</TableCell>}
                      {filters.spouseName && <TableCell>{resident.spouseName}</TableCell>}
                      {filters[displayAddressType] && <TableCell>{resident[displayAddressType]}</TableCell>}
                      {filters.lotNumber && <TableCell>{resident.lotNumber}</TableCell>}
                      {filters.email && <TableCell>{resident.email}</TableCell>}
                      {filters.phoneNumber && <TableCell>{formatPhoneNumber(resident.phoneNumber)}</TableCell>}
                      {filters.accountNumber && <TableCell>{resident.accountNumber}</TableCell>}
                      <TableCell>
                        <Tooltip title="Edit resident">
                          <IconButton 
                            size="small" 
                            onClick={() => handleOpen(resident)}
                            sx={{ color: 'primary.main' }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>

      <Dialog 
        open={open} 
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2
          }
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
            {editing ? 'Edit Resident' : 'Add New Resident'}
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ pt: 2 }}>
          <Stack spacing={2}>
            <TextField 
              autoFocus 
              name="firstName" 
              label="First Name" 
              fullWidth 
              value={currentResident.firstName} 
              onChange={handleInputChange}
              size="small"
            />
            <TextField 
              name="lastName" 
              label="Last Name" 
              fullWidth 
              value={currentResident.lastName} 
              onChange={handleInputChange}
              size="small"
            />
            <TextField 
              name="spouseName" 
              label="Spouse Name" 
              fullWidth 
              value={currentResident.spouseName} 
              onChange={handleInputChange}
              size="small"
            />
            <TextField 
              name="propertyAddress" 
              label="Property Address" 
              fullWidth 
              value={currentResident.propertyAddress} 
              onChange={handleInputChange}
              size="small"
            />
            <FormControlLabel
              control={
                <Checkbox 
                  checked={isMailingSameAsProperty} 
                  onChange={handleCheckboxChange}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                />
              }
              label="Mailing address is the same as property address"
            />
            {!isMailingSameAsProperty && (
              <TextField 
                name="mailingAddress" 
                label="Mailing Address" 
                fullWidth 
                value={currentResident.mailingAddress} 
                onChange={handleInputChange}
                size="small"
              />
            )}
            <TextField 
              name="lotNumber" 
              label="Lot Number" 
              fullWidth 
              value={currentResident.lotNumber} 
              onChange={handleInputChange}
              size="small"
            />
            <TextField 
              name="email" 
              label="Email" 
              type="email" 
              fullWidth 
              value={currentResident.email} 
              onChange={handleInputChange}
              size="small"
            />
            <TextField 
              name="phoneNumber" 
              label="Phone Number" 
              fullWidth 
              value={currentResident.phoneNumber} 
              onChange={handleInputChange}
              size="small"
            />
            <TextField 
              name="accountNumber" 
              label="Account Number" 
              fullWidth 
              value={currentResident.accountNumber} 
              onChange={handleInputChange}
              size="small"
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          {editing && (
            <Tooltip title="Delete resident">
              <IconButton 
                onClick={handleDeleteResident}
                sx={{ 
                  color: 'error.main',
                  mr: 'auto'
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
          <Button 
            onClick={handleClose}
            sx={{ 
              textTransform: 'none',
              minWidth: 100
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit}
            variant="contained"
            sx={{ 
              textTransform: 'none',
              minWidth: 100
            }}
          >
            {editing ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>

      <Menu 
        anchorEl={anchorEl} 
        open={isMenuOpen} 
        onClose={handleFilterClose}
        PaperProps={{
          sx: {
            mt: 1,
            boxShadow: 3,
            borderRadius: 2
          }
        }}
      >
        <List sx={{ width: 200, py: 1 }}>
          {Object.keys(filters).map((filter) => (
            <ListItem key={filter} dense>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters[filter]}
                    onChange={handleFilterChange}
                    name={filter}
                    size="small"
                  />
                }
                label={
                  <Typography variant="body2">
                    {filter.replace(/([A-Z])/g, ' $1').charAt(0).toUpperCase() + filter.slice(1)}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Menu>
    </Container>
  );
};

export default Residents;
